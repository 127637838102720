import FilesIcon from "@/assets/images/iconsax/AttachSquare-Linear-32px.svg?react";
import CalendarIcon from "@/assets/images/iconsax/Calendar-Linear-32px.svg?react";
import RoomsIcon from "@/assets/images/iconsax/Category-Linear-32px.svg?react";
import DMsIcon from "@/assets/images/iconsax/ChatBubbles-Linear-32px.svg?react";
import TasksIcon from "@/assets/images/iconsax/TaskSquare-Linear-32px.svg?react";
import { NotificationCenterDrawer } from "@/components/homePage/drawers/notificationCenter/NotificationCenterDrawer";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { type ITab } from "@/components/shared/layout/TabMenuComponent";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useDMsBadgeQuery } from "@/hooks/queries/dm/useDmsBadgeQuery";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useHomePageTabs = () => {
  const me = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const { data: dmsBadge } = useDMsBadgeQuery();
  const { openDrawer, closeDrawer } = useContext(DrawerContext);

  useEffect(() => {
    openDrawer(
      {
        drawerType: DrawerType.NOTIFICATIONS_CENTER,
        component: <NotificationCenterDrawer />,
      },
      true,
    );
    closeDrawer(DrawerType.PIN_BOARD, true);

    return () => {
      closeDrawer(DrawerType.NOTIFICATIONS_CENTER, true);
    };
  }, []);

  const tabs: ITab[] = [
    {
      title: useMemo(() => {
        return me?.mainOrganisation != null ? t("homePage.tabs.rooms.title") : t("homePage.tabs.rooms.titlePersonal");
      }, [me]),
      link: "/dashboard/rooms",
      icon: <RoomsIcon className="gradient-icon stroke h-[1.1rem] w-[1.1rem] stroke-2" />,
      align: "left",
    },
    {
      title: t("homePage.tabs.files.title"),
      link: "/dashboard/files",
      icon: <FilesIcon className="gradient-icon stroke h-[1.25rem] w-[1.25rem] stroke-2" />,
      align: "left",
    },
    {
      title: t("homePage.tabs.calendar.title"),
      link: "/dashboard/calendar",
      icon: <CalendarIcon className="gradient-icon stroke h-[1.25rem] w-[1.25rem]" />,
      align: "left",
      disabled: true,
      disabledText: t("homePage.tabs.disabledText"),
    },
    {
      title: t("homePage.tabs.tasks.title"),
      link: "/dashboard/tasks",
      icon: <TasksIcon className="gradient-icon stroke h-[1.25rem] w-[1.25rem]" />,
      align: "left",
    },
    {
      title: t("homePage.tabs.dms.title"),
      link: "/dashboard/dms",
      icon: <DMsIcon className="gradient-icon stroke h-[1.25rem] w-[1.25rem] stroke-2" />,
      align: "right",
      withBadge: useMemo(() => dmsBadge, [dmsBadge]),
    },
  ];

  return { tabs };
};
