import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { createContext, useCallback, useContext } from "react";

export interface IPreferences {
  soundForNewMessage?: boolean;
  notificationSoundVolume?: number;
  darkMode?: boolean;
  starredRooms?: string[];
  showArchivedRooms?: boolean;
  ["roomOrder.organisation"]?: string[];
  ["roomOrder.project"]?: string[];
  // TODO type all preferences to avoid any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface IPreferencesContextType {
  preferences: IPreferences;
  setPreferences: (preferences: IPreferences) => void;
}

export const PreferencesContext = createContext<IPreferencesContextType>({ preferences: {}, setPreferences: () => {} });

export const usePreferences = () => {
  const me = useContext(AuthenticationContext);

  const { preferences, setPreferences } = useContext(PreferencesContext);

  const setPreference = useCallback(
    (preferenceKey: string, value: unknown) => {
      if (me == null) return;
      if (value == null) {
        localStorage.removeItem(`u${me?.id}.preferences.${preferenceKey}`);
      } else {
        localStorage.setItem(`u${me.id}.preferences.${preferenceKey}`, JSON.stringify(value));
      }
      setPreferences({ ...getAllPreferences(me.id), [preferenceKey]: value });
    },
    [me, preferences],
  );

  const getAllPreferences = useCallback(
    (id: string): IPreferences => {
      if (id == null) return {};
      const preferences: IPreferences = {};
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(`u${id}.preferences.`)) {
          const preference: string = key.replace(`u${id}.preferences.`, "");
          preferences[preference] = JSON.parse(localStorage.getItem(key) ?? "null");
        }
      });
      return preferences;
    },
    [me],
  );

  return { setPreference, getAllPreferences };
};
