import { type IRoomIdName } from "@/api/client";
import { RoomAvatarComponent } from "@/components/shared/room/RoomAvatarComponent";
import { useRoomPicker } from "@/components/shared/room/RoomPickerComponent/useRoomPicker";
import { RoomPickerPopinComponent } from "@/components/shared/room/RoomPickerPopinComponent/RoomPickerPopinComponent";
import { useMobile } from "@/hooks/shared/useMobile";
import { type FunctionComponent, type HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { BsFillCaretDownFill as SelectorIcon } from "react-icons/bs";
import { FaDoorOpen as RoomIcon } from "react-icons/fa";

interface IRoomPickerComponentProps {
  room: IRoomIdName | null;
  onRoomChange?: (room: IRoomIdName | null) => void;
  className?: HTMLAttributes<HTMLDivElement>["className"];
  size?: "lg" | "md" | "sm";
  isSelector?: boolean;
}

export const RoomPickerComponent: FunctionComponent<IRoomPickerComponentProps> = ({
  room,
  onRoomChange,
  className,
  size = "md",
  isSelector = false,
}) => {
  const { name, isRoomTooltipVisible, onCloseTooltip, onRoomClick, onRoomSelected } = useRoomPicker(room, onRoomChange);
  const { isIos } = useMobile();
  const { t } = useTranslation();

  return (
    <RoomPickerPopinComponent
      isVisible={isRoomTooltipVisible}
      onCloseTooltip={onCloseTooltip}
      onRoomSelected={onRoomSelected}
      hasRemoveRoomOption={isSelector && room != null}
    >
      <div
        className={`flex flex-row items-center justify-between ${onRoomChange != null ? "cursor-pointer" : ""} ${
          isSelector ? "rounded-md border border-zinc-200 bg-zinc-100 px-2 py-1 hover:opacity-75" : ""
        } ${className ?? ""}`}
        onClick={onRoomClick}
      >
        <div className="flex flex-row items-center">
          {isSelector && room == null ? null : (
            <div
              className={`room-image rounded-md ${size === "lg" ? "h-6 w-6" : size === "md" ? "h-5 w-5" : "h-4 w-4"} aspect-square border ${
                isSelector ? "border-stone-400" : "border-stone-300"
              } overflow-hidden transition-all duration-200 ease-in-out dark:border-dark-700 ${
                room == null ? "flex items-center justify-center" : ""
              }`}
            >
              {room != null ? (
                <RoomAvatarComponent
                  roomId={room?.id}
                  size="xs"
                  className={`h-full transition-all duration-200 hover:bg-dark-25 dark:bg-dark-800 dark:hover:bg-dark-700 ${isIos ? "-mt-1" : ""}`}
                />
              ) : (
                <RoomIcon
                  className={`${isSelector ? "text-zinc-700" : "text-zinc-500"}`}
                  style={{ fontSize: size === "lg" ? 12 : size === "md" ? 10 : 8 }}
                />
              )}
            </div>
          )}
          <div
            className={`${size === "lg" ? "ml-2" : "ml-1"} font-normal ${size === "lg" || size === "sm" ? "text-sm" : "text-xs"} ${
              isSelector ? "text-zinc-700" : "text-zinc-500"
            }`}
          >
            {room != null ? name : isSelector ? t("room.picker.selectRoom") : t("room.picker.noRoom")}
          </div>
        </div>
        {onRoomChange != null ? (
          isSelector ? (
            <SelectorIcon className="ml-1 h-3 w-3 text-zinc-700" />
          ) : (
            <div className="text-xs font-semibold text-sky-600">{t("room.picker.pick")}</div>
          )
        ) : null}
      </div>
    </RoomPickerPopinComponent>
  );
};
