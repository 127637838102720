import { type HTMLAttributes } from "react";
import { Trans, useTranslation } from "react-i18next";

interface ListOfTranslatedKeysProps extends HTMLAttributes<HTMLDivElement> {
  start?: number;
  keyString: string;
  // TODO refacto whole component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
  showBullet?: boolean;
}

export const ListOfTranslatedKeysComponent = ({ start = 1, keyString, showBullet = true, params = {} }: ListOfTranslatedKeysProps): JSX.Element => {
  const { t } = useTranslation();
  const objectOfTranslatedKeys: string[] = t(keyString, { returnObjects: true, ...params }) as string[];

  const keys = Object.keys(objectOfTranslatedKeys);
  const keysToRender = keys.slice(start - 1, keys.length);
  return (
    <>
      {keysToRender.map((key, index) => {
        return showBullet ? (
          <li key={index} className="list-inside list-disc text-sm font-[300] text-zinc-400">
            <Trans>{objectOfTranslatedKeys[index + start]}</Trans>
          </li>
        ) : (
          <p key={index} className="mb-2 text-sm font-[300]">
            <Trans>{objectOfTranslatedKeys[index + start]}</Trans>
          </p>
        );
      })}
    </>
  );
};
