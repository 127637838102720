import { type IOrganisationMember, type IRoomMember } from "@/api/client";
import { closeModal } from "@/components/shared/modal/ModalService";
import { ModalNames } from "@/constants/modalNames";
import { QueryKeys } from "@/constants/queryKeys";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { type IFormattedChannel } from "@/interfaces/channel";
import { type IModalAction } from "@/interfaces/modal";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export const useConfirmRoleChangeOrDeletionModalContent = (
  scope: string,
  member: IOrganisationMember | IRoomMember | IFormattedChannel,
  action: (transferToId?: string) => Promise<void> | (() => void) | undefined,
  isDeletion: boolean,
  isArchive: boolean,
  hasGuests: boolean,
) => {
  const me = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const isMe = useMemo(() => {
    if (me == null) return false;
    return member.userId === me.id;
  }, [me]);

  const queryClient = useQueryClient();

  const memberList = useMemo(() => {
    const members: Array<IRoomMember | IOrganisationMember> =
      scope === "organisation"
        ? queryClient.getQueryData([QueryKeys.ORGANISATIONS, me?.mainOrganisation?.organisationId, QueryKeys.MEMBERS]) ?? []
        : queryClient.getQueryData([QueryKeys.ROOMS, (member as IRoomMember).roomId, QueryKeys.MEMBERS]) ?? [];

    return members
      .filter((_member) => _member.userId !== me?.id && _member.role?.type !== "guest")
      .map((_member) => {
        return {
          value: _member.id,
          label: `${_member.firstName ?? ""} ${_member.lastName ?? ""}`,
        };
      });
  }, [me]);

  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);

  const actions: IModalAction[] = useMemo(() => {
    if (me == null) return [];
    let actionName = t(`members.${scope}.transferRights`);
    let actionToTrigger = action;

    if (isArchive) {
      if (hasGuests) {
        actionName = t(`members.${scope}.leaveAndArchiveWithGuests`);
      } else {
        actionName = t(`members.${scope}.leaveAndArchive`);
      }
    } else if ((isDeletion && !isMe) || (isDeletion && isMe && member?.role?.type !== "owner")) {
      // If it's a deletion and I'm not the user being deleted or I'm the user being deleted but I'm not the owner
      actionName = t(`members.${scope}.removeAndDelete`);
      // If it's a deletion and I'm the user being deleted and I'm the owner
    } else if (isDeletion && isMe && member?.role?.type === "owner") {
      actionName = t(`members.${scope}.transferAndDelete`);

      actionToTrigger = async () => {
        await action(selectedMemberId ?? undefined);
      };
    }

    return [
      {
        action: () => {
          closeModal(ModalNames.CONFIRM_ROLE_CHANGE_OR_DELETION);
        },
        label: t("general.cancel"),
        type: "secondary",
        disabled: false,
      },
      {
        action: async () => {
          closeModal(ModalNames.CONFIRM_ROLE_CHANGE_OR_DELETION);
          await actionToTrigger();
        },
        label: actionName,
        type: "danger",
        disabled: isDeletion && isMe && member?.role?.type === "owner" && selectedMemberId == null && !isArchive && scope !== "channel",
      },
    ];
  }, [isDeletion, isMe, selectedMemberId, isArchive, hasGuests]);

  const line1 = useMemo(() => {
    if (isArchive) {
      return t(`members.${scope}.archive.1`);
    }

    if (isMe && member?.role?.slug === `default-${scope}-owner`) {
      return t(`members.${scope}.delete.me.1`);
    } else {
      return t(`members.${scope}.delete.user.1`);
    }
  }, [isMe]);

  return {
    actions,
    line1,
    memberList,
    selectedMemberId,
    isMe,
    setSelectedMemberId,
  };
};
