// organize-imports-ignore
import "@/assets/styles/quill.snow.css";
import "@/assets/styles/CustomStyleQuill.scss";
import { RichTextEditorToolbarComponent } from "@/components/shared/richTextEditor/RichTextEditorToolbarComponent";
import { PreferencesContext } from "@/hooks/shared/usePreferences";
import { useRichTextEditor } from "@/hooks/shared/useRichTextEditor";
import { type IRichTextEditorRef } from "@/interfaces/richTextEditor";
import emojis from "@emoji-mart/data/sets/14/apple.json";
import Picker from "@emoji-mart/react";
import { type DeltaStatic } from "quill";
import { forwardRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import ReactQuill from "react-quill";
import { ForwardedMessageComponent } from "@/components/roomPage/tabs/conversation/message/forwardMessageModal/ForwardedMessageComponent";
import { IoClose } from "react-icons/io5";
import { type DeltaContent } from "@/api/client";

interface IRichTextEditorProps {
  contextType: string;
  contextId: string;
  defaultValue?: DeltaStatic;
  isEditing?: boolean;
  deltaBeforeEditing?: DeltaContent | null;
  isMessagePreview?: boolean;
  theme?: string;
  messageId?: string;
  onBlur?: () => void;
  placeholder?: string;
}

export const RichTextEditorComponent = forwardRef<IRichTextEditorRef, IRichTextEditorProps>(
  (
    {
      contextType,
      contextId,
      defaultValue,
      isEditing = false,
      isMessagePreview = false,
      theme = "snow",
      messageId,
      onBlur,
      placeholder,
      deltaBeforeEditing = null,
    }: IRichTextEditorProps,
    ref,
  ): JSX.Element => {
    const { t } = useTranslation();
    const { preferences } = useContext(PreferencesContext);

    const {
      toolbarRef,
      modules,
      editorId,
      quill,
      addMessage,
      editMessage,
      isEmpty,
      isDirty,
      handleChange,
      handleKeyUp,
      handleBlur,
      handleEmojiSelect,
      isEmojiPickerOpen,
      setIsEmojiPickerOpen,
      setSelectionPosition,
      isTextFormatOpen,
      setIsTextFormatOpen,
      forwardMessageState,
      setForwardMessageState,
      onInsertMentionCharacter,
    } = useRichTextEditor(ref, contextType, contextId, isEditing, isMessagePreview, deltaBeforeEditing, defaultValue, messageId, onBlur);

    return (
      <>
        {forwardMessageState != null && !isMessagePreview && (
          <div className="relative flex items-center rounded-t-md border border-b-0 border-zinc-200 bg-zinc-50 p-2 dark:bg-dark-800">
            <ForwardedMessageComponent forward={forwardMessageState} showForward={false} key={forwardMessageState.id} />
            <button
              type="button"
              className="ml-auto mr-2"
              onClick={() => {
                setForwardMessageState(undefined);
              }}
            >
              <IoClose className="h-5 w-5" />
            </button>
          </div>
        )}
        <div
          className={`${contextId} relative ${
            isMessagePreview && !isEditing ? "" : "border border-zinc-200 bg-zinc-50 dark:border-dark-700 dark:bg-dark-800"
          }
          ${forwardMessageState != null ? "rounded-b-md" : "rounded-md"}
          ${isTextFormatOpen ? "pt-6" : ""}
          `}
        >
          <div
            className={`${isEditing ? "editing" : ""} ${isMessagePreview ? "preview" : ""}`}
            onClick={() => {
              if (toolbarRef.current != null) {
                toolbarRef.current.checkIfTextFormatOpen();
              }
            }}
          >
            <ReactQuill
              ref={quill}
              // value={value}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              onBlur={handleBlur}
              onFocus={() => {
                setSelectionPosition(null);
              }}
              modules={modules}
              readOnly={!isEditing && isMessagePreview}
              formats={["emoji", "mention", "bold", "italic", "underline", "strike", "list", "bullet", "indent", "link", "color"]}
              placeholder={placeholder ?? (!isMessagePreview ? t("textEditor.placeholder") : "")}
              theme={theme}
            />

            <RichTextEditorToolbarComponent
              contextType={contextType}
              contextId={contextId}
              editorId={editorId}
              editorRef={quill}
              action={isEditing ? editMessage : addMessage}
              isEditing={isEditing}
              isDirty={isDirty}
              isMessagePreview={isMessagePreview}
              isEmpty={isEmpty}
              setIsEmojiPickerOpen={setIsEmojiPickerOpen}
              isTextFormatOpen={isTextFormatOpen}
              setIsTextFormatOpen={setIsTextFormatOpen}
              hideSendButton={contextType === "forwardedMessage" || contextType === "taskDescription"}
              hideFilesUploadButton={contextType === "forwardedMessage" || contextType === "taskDescription" || contextType === "email"}
              onInsertMentionCharacter={onInsertMentionCharacter}
            />
          </div>
          {isEmojiPickerOpen && quill.current != null && (
            <Portal>
              <div className="absolute left-0 top-0 z-50">
                <div
                  className="absolute bottom-14"
                  style={{
                    left: (quill.current.editingArea as Element).getBoundingClientRect().left,
                  }}
                >
                  <Picker data={emojis} onEmojiSelect={handleEmojiSelect} theme={preferences.darkMode === true ? "dark" : "light"} set="apple" />
                </div>
                <div
                  className="h-screen w-screen bg-white/0"
                  onClick={() => {
                    setIsEmojiPickerOpen(false);
                  }}
                ></div>
              </div>
            </Portal>
          )}
        </div>
      </>
    );
  },
);
RichTextEditorComponent.displayName = "RichTextEditorComponent";
