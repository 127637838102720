import { type IChannel } from "@/api/client";
import { RoomAvatarComponent } from "@/components/shared/room/RoomAvatarComponent";
interface IChannelOptionComponentProps {
  option: {
    channel: IChannel;
    label: string;
    value: string;
  };
}
export const ChannelOptionComponent = ({ option }: IChannelOptionComponentProps) => {
  return (
    <span className="flex items-center">
      <div className="mr-2 w-6">
        <RoomAvatarComponent roomId={option.channel.room.id} />
      </div>
      <span>
        {option.channel.room.name}
        {" • "}
        <span className="text-sky-600">#{option.channel.name}</span>
      </span>
    </span>
  );
};
