import {
  type ApiUnprocessableEntityException,
  type IBadRequestError,
  type IForbiddenError,
  type INotFoundError,
  type IUnauthorizedError,
} from "@/api/client";
import { AUTHENTICATION_ROUTES } from "@/constants/apiRoutes";
import { HttpExceptionsBadRequestMessagesEnum, HttpExceptionsEnum } from "@/constants/errorTypes";
import { logout, refreshToken } from "@/services/AuthService";
import { captureException } from "@sentry/react";
import { type AxiosError } from "axios";
import { type TFunction } from "i18next";
import { toast } from "react-hot-toast";

const unGuardedRoutes = [
  AUTHENTICATION_ROUTES.LOGIN,
  AUTHENTICATION_ROUTES.REGISTER,
  AUTHENTICATION_ROUTES.CONFIRM_EMAIL,
  AUTHENTICATION_ROUTES.REFRESH_TOKEN,
];

const noReportRoutes = [
  AUTHENTICATION_ROUTES.LOGIN,
  AUTHENTICATION_ROUTES.LOGOUT,
  AUTHENTICATION_ROUTES.REGISTER,
  AUTHENTICATION_ROUTES.CONFIRM_EMAIL,
  AUTHENTICATION_ROUTES.REFRESH_TOKEN,
];

const getToastTextByError = (
  error: AxiosError<IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiUnprocessableEntityException>,
  t: TFunction<"translation", undefined>,
): string | null => {
  const message = error.response?.data?.message;

  const defaultToastErrorMessage = (error.config as { defaultToastErrorMessage?: string | null }).defaultToastErrorMessage;
  if (message == null) {
    if (defaultToastErrorMessage !== undefined) {
      return defaultToastErrorMessage;
    }

    return t("error.unknown.undefined");
  }

  let errors;

  switch (message) {
    case HttpExceptionsEnum.BAD_REQUEST:
      errors = error.response?.data?.errors as { email?: string[]; name?: string[] };
      if (errors?.email?.includes(HttpExceptionsBadRequestMessagesEnum.USER_EXISTING_IN_OTHER_ORGANISATION) === true) {
        return t(`error.bad_request.email.user_existing_in_other_organisation`);
      }
      if (errors?.email?.includes(HttpExceptionsBadRequestMessagesEnum.INCORRECT_VALUE) != null) {
        return t(`error.bad_request.email.incorrect_value`);
      }
      if (errors?.email?.includes(HttpExceptionsBadRequestMessagesEnum.DOMAIN_INVALID) != null) {
        return t(`error.bad_request.email.domain_invalid`);
      }

      break;
    case HttpExceptionsEnum.CONFLICT:
      errors = error.response?.data?.errors as { name?: string[] };
      if (errors.name?.includes(HttpExceptionsBadRequestMessagesEnum.UNIQUE_VALUE_ALREADY_TAKEN) != null) {
        return t(`error.conflict.name.unique_value_already_taken`);
      }
      break;
    case HttpExceptionsEnum.USER_MUST_BE_FIRST_ADDED_IN_ORGANISATION:
      return t(`error.user_must_be_first_added_in_organisation`);

    case HttpExceptionsEnum.USER_EXISTING_WITH_OTHER_PROVIDER:
      errors = error.response?.data?.errors as { email?: string[] };
      if (errors.email?.includes(HttpExceptionsBadRequestMessagesEnum.USER_EXISTING_WITH_OTHER_PROVIDER) != null) {
        // @ts-expect-error - Back types are not up to date
        const details = error.response?.data?.details as {
          expectedProvider?: "GOOGLE" | "APPLE" | "EMAIL";
        };

        if (details.expectedProvider === "GOOGLE") {
          return t(`error.providerMismatch.google`);
        }
        if (details.expectedProvider === "APPLE") {
          return t(`error.providerMismatch.apple`);
        }
        if (details.expectedProvider === "EMAIL") {
          return t(`error.providerMismatch.email`);
        }
      }
      return t(`error.providerMismatch.unknown`);
  }

  if (defaultToastErrorMessage !== undefined) {
    return defaultToastErrorMessage;
  }

  return t(`error.unknown.message`, { message });
};

type AError = AxiosError<IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiUnprocessableEntityException>;

const shouldDisplayToastMessage = (error: AError) => {
  if (error.config?.url != null) {
    if (noReportRoutes.includes(error.config.url)) {
      return false;
    }
    if (error.config.url.startsWith("/api/v1/tasks/") && error.config.method === "get") {
      return false;
    }
  }

  return true;
};

export const handleIncomingError = async (error: AError | Error, t: TFunction<"translation", undefined>, forceToastMessage?: boolean) => {
  captureException(error);

  if ((error as AError).isAxiosError) {
    const err = error as AError;

    const isGoogleAuthRequiredError =
      // @ts-expect-error - errors does not exists in type
      err.response?.data?.errors?.includes(HttpExceptionsBadRequestMessagesEnum.GOOGLE_AUTHENTICATION_REQUIRED) === true;

    if (
      err.response?.status === 401 &&
      err.response.config?.url != null &&
      !unGuardedRoutes.includes(err.response.config.url) &&
      !isGoogleAuthRequiredError
    ) {
      if (localStorage.getItem("refreshToken") !== "" && localStorage.getItem("refreshToken") != null) {
        await refreshToken();
      } else {
        void logout().finally(() => {
          window.location.href = "/";
        });
      }
    } else if (forceToastMessage === true || shouldDisplayToastMessage(err)) {
      const toastText = getToastTextByError(err, t);
      if (toastText != null) {
        toast.error(toastText);
      }
    }
  }

  throw error;
};
