import { type ButtonHTMLAttributes } from "react";
interface INotificationFilterButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected: boolean;
  children: string | JSX.Element;
  count?: number;
}

export const NotificationFilterButton = ({
  selected = false,
  children = "button",
  count = 0,
  ...props
}: INotificationFilterButtonProps): JSX.Element => {
  return (
    <>
      <button
        {...props}
        className={`
        hover:gradient-border hover:gradient-border-rounded-md hover:gradient-border-2 mr-2 flex h-7 w-1/2 items-center justify-center rounded-lg px-2
        py-0.5 text-sm font-semibold last:m-0
        ${
          selected
            ? "gradient-border gradient-border-rounded-md gradient-border-2 text-zinc-800 dark:bg-dark-600/5 dark:text-dark-100"
            : "border-1 hover:gradient-border hover:gradient-border-rounded-md hover:gradient-border-2 border bg-gray-600/5 text-[#919192] hover:border-0 hover:p-[3px]"
        }`}
      >
        {children} {count > 0 ? <>({count})</> : null}
      </button>
    </>
  );
};
