import { type IOrganisationMember, type IRoomMember } from "@/api/client";
import { useConfirmRoleChangeOrDeletionModalContent } from "@/components/header/profileDropdown/organisationModal/tabs/orgMembers/useConfirmRoleChangeOrDeletionModalContent";
import { CustomSelectComponent } from "@/components/shared/form/select/CustomSelectComponent";
import { ListOfTranslatedKeysComponent } from "@/components/shared/i18n/ListOfTranslatedKeysComponent";
import { PortalInPlaceComponent } from "@/components/shared/layout/PortalInPlaceComponent";
import { OrganisationMemberComponent } from "@/components/shared/members/OrganisationMemberComponent";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { type IFormattedChannel } from "@/interfaces/channel";
import { useTranslation } from "react-i18next";

interface IConfirmRoleChangeOrDeletionModalContentProps {
  member: IOrganisationMember | IRoomMember | IFormattedChannel;
  isDeletion: boolean;
  action: (transferToId?: string) => Promise<void> | (() => void) | undefined;
  scope: "organisation" | "room" | "channel";
  isArchive?: boolean;
  hasGuests?: boolean;
}

export const ConfirmRoleChangeOrDeletionModalContent = ({
  member,
  isDeletion = false,
  action,
  scope,
  isArchive = false,
  hasGuests = false,
}: IConfirmRoleChangeOrDeletionModalContentProps): JSX.Element => {
  const { t } = useTranslation();
  const { actions, line1, memberList, selectedMemberId, setSelectedMemberId, isMe } = useConfirmRoleChangeOrDeletionModalContent(
    scope,
    member,
    action,
    isDeletion,
    isArchive,
    hasGuests,
  );

  return (
    <>
      <ModalTabComponent footer={<ModalFooterComponent key={selectedMemberId} actions={actions}></ModalFooterComponent>}>
        <div>
          {isDeletion ? (
            <>
              <h2 className="mb-1 font-semibold text-zinc-500">{line1}</h2>
              <ul className="mb-4">
                {isArchive ? (
                  hasGuests ? (
                    <ListOfTranslatedKeysComponent start={2} keyString={`members.${scope}.archiveWithGuests`} />
                  ) : (
                    <ListOfTranslatedKeysComponent start={2} keyString={`members.${scope}.archive`} />
                  )
                ) : isMe && member?.role?.slug === `default-${scope}-owner` ? (
                  <ListOfTranslatedKeysComponent start={2} keyString={`members.${scope}.delete.me`} />
                ) : (
                  <ListOfTranslatedKeysComponent start={2} keyString={`members.${scope}.delete.user`} />
                )}
              </ul>
            </>
          ) : (
            <>
              <h2 className="mb-1 font-semibold text-zinc-500">{t(`members.${scope}.transfer.line1`)}</h2>
              <ul className="mb-4">
                <ListOfTranslatedKeysComponent start={2} keyString={`members.${scope}.transfer`} />
              </ul>
            </>
          )}

          {!isArchive && isDeletion && isMe && member?.role?.type === "owner" && scope !== "channel" ? (
            <>
              <h2 className="mb-1 font-semibold text-zinc-500">{t(`members.${scope}.selectedMember`)}</h2>
              <PortalInPlaceComponent>
                <CustomSelectComponent<string>
                  onChange={(e) => {
                    setSelectedMemberId(e?.value ?? null);
                  }}
                  options={memberList}
                  isMulti={false}
                />
              </PortalInPlaceComponent>
            </>
          ) : !isMe ? (
            <>
              <h2 className="mb-1 font-semibold text-zinc-500">{t(`members.${scope}.selectedMember`)}</h2>
              <OrganisationMemberComponent member={member as IOrganisationMember} isDisabled />
            </>
          ) : null}
        </div>
      </ModalTabComponent>
    </>
  );
};
