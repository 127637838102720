import { type IRoomMember } from "@/api/client";
import { useBillingConfirm } from "@/components/billing/modals/useBillingConfirm";
import { RoomModal } from "@/components/homePage/modals/room/RoomModal";
import { RoomCard } from "@/components/homePage/tabs/rooms/RoomCard";
import { useRoomList } from "@/components/homePage/tabs/rooms/useRoomList";
import { ToggleComponent } from "@/components/shared/form/toggle/ToggleComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { LimitationResponseCodes } from "@/constants/limitationResponseCode";
import { ModalNames } from "@/constants/modalNames";
import { roomType } from "@/constants/room";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useRoomListReorder } from "@/hooks/homePage/hoomList/useRoomListReorder";
import { useVerifyRoomCreationMutation } from "@/hooks/mutations/rooms/useVerifyRoomCreationMutation";
import { PreferencesContext, usePreferences } from "@/hooks/shared/usePreferences";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { CgAdd as AddIcon } from "react-icons/cg";

interface IRoomListProps {
  rooms: IRoomMember[];
  canAddNewRoom?: boolean;
  icon?: JSX.Element;
  title?: string;
  type?: string;
  info?: string;
}

export const RoomList = ({ rooms, title = "", icon, canAddNewRoom = false, type = roomType.PROJECT, info }: IRoomListProps): JSX.Element => {
  const me = useContext(AuthenticationContext);
  const { preferences } = useContext(PreferencesContext);
  const { setPreference } = usePreferences();
  const { sensors, handleDragStart, handleDragEnd, orderedRooms, isDragging, DndContext, SortableContext, rectSortingStrategy } =
    useRoomListReorder(rooms);
  const { mutateAsync: initializeRoomCreation } = useVerifyRoomCreationMutation();
  const { t } = useTranslation();

  useRoomList();
  const { openBillingConfirmModal } = useBillingConfirm();
  const openCreateRoomModal = useCallback(async () => {
    const { responseCode, quotaData } = await initializeRoomCreation();

    if (responseCode !== LimitationResponseCodes.OK) {
      openBillingConfirmModal(responseCode as LimitationResponseCodes, quotaData);
      return;
    }
    openModal(
      ModalNames.CREATE_ROOM,
      <RoomModal title={type === roomType.PROJECT ? t("createRoomModal.tabs.titleRoom") : t("createRoomModal.tabs.titleOrganisation")} />,
    );
  }, []);

  return (
    <section className="roomItem-list group pb-5">
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors}>
        {title !== "" && me?.mainOrganisation != null && (
          <h2 className="flex items-center border-y border-y-zinc-100 bg-dark-25 px-layout py-2 text-sm text-zinc-500 group-first:border-t-0 dark:border-y-dark-700 dark:bg-dark-800">
            {icon !== undefined && <i className="mr-1.5">{icon}</i>}
            <span>{title}</span>
            {info != null && (
              <TooltipComponent content={info} placement="right" className="w-[75%]">
                <button type="button">
                  <AiOutlineInfoCircle className="ml-1 aspect-square h-full text-zinc-500 dark:text-dark-300" />
                </button>
              </TooltipComponent>
            )}
            {type === roomType.PROJECT && (
              <div className="ml-auto flex items-center">
                <span className="mr-2">{t("homePage.tabs.rooms.projects.archivedToggle")}</span>
                <ToggleComponent
                  isEnabled={preferences.showArchivedRooms === true}
                  onToggle={(e) => {
                    setPreference("showArchivedRooms", e);
                  }}
                  size="sm"
                />
              </div>
            )}
          </h2>
        )}
        <div className="mt-4 flex flex-row flex-wrap px-4">
          {canAddNewRoom && preferences.showArchivedRooms !== true && (
            <button
              type="button"
              onClick={openCreateRoomModal}
              style={{ width: 120, height: 120 }}
              className="gradient-border gradient-border-rounded-2xl gradient-border-4 hover:gradient-border-7 group/create mx-5 mb-10 mt-1 flex aspect-square flex-col items-center justify-center rounded-lg p-0 transition-all hover:shadow-md"
            >
              <AddIcon className="h-12 w-12 text-neutral-400 transition-all group-hover/create:text-zinc-500" />
              <span className="-mb-2 mt-1 text-sm font-[500] text-zinc-500 transition-all group-hover/create:text-zinc-500 dark:text-dark-500 max-sm:hidden">
                {type === roomType.PROJECT ? t("homePage.tabs.rooms.roomCard.createRoom") : "Create organisation"}
              </span>
            </button>
          )}
          <SortableContext items={orderedRooms} strategy={rectSortingStrategy}>
            {orderedRooms.map((roomItem) => (
              <RoomCard key={roomItem.roomId} room={roomItem} isDragging={isDragging} />
            ))}
          </SortableContext>
        </div>
      </DndContext>
    </section>
  );
};
