import { type IChannel, type IMessage, type IMessageForward } from "@/api/client";
import { ChannelOptionComponent } from "@/components/roomPage/tabs/conversation/message/forwardMessageModal/ChannelOptionComponent";
import { ForwardedMessageComponent } from "@/components/roomPage/tabs/conversation/message/forwardMessageModal/ForwardedMessageComponent";
import { useForwardMessage } from "@/components/roomPage/tabs/conversation/message/forwardMessageModal/useForwardMessage";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { closeModal } from "@/components/shared/modal/ModalService";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { RichTextEditorComponent } from "@/components/shared/richTextEditor/RichTextEditorComponent";
import { type IModalAction } from "@/interfaces/modal";
import { type IRichTextEditorRef } from "@/interfaces/richTextEditor";
import { api } from "@/services/HttpService";
import { debounce } from "@/utils/utilities";
import { useCallback, useMemo, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";

interface IForwardMessageModalProps {
  messageToShare: IMessage;
}

interface IOption {
  value: string;
  label: string;
  channel: IChannel;
}
export const ForwardMessageModalTabComponent = ({ messageToShare }: IForwardMessageModalProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customStyles, ClearIndicator } = useForwardMessage();
  const [selectedOption, setSelectedOption] = useState<IOption>();
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const editorRef = useRef<IRichTextEditorRef>(null);
  const handleChange = useCallback(
    (event: unknown) => {
      const option = event as IOption;
      setSelectedOption(option);
    },
    [setSelectedOption],
  );

  const loadOptions = (inputValue: string, callback: (options: Array<{ value: string; label: string }>) => void) => {
    if (messageToShare.conversation == null) return;
    void api.channels
      .searchChannels({
        channelSearchQuery: inputValue,
        channelSearchFromId: messageToShare.conversation.channel?.id ?? "",
      })
      .then((response) => {
        const options = response.data.data.map((item) => ({
          value: item.conversation.id,
          label: `${item.room.name}>#${item.name as string}` ?? "",
          channel: item,
        }));
        callback(options);
      });
  };

  const debouncedLoadOptions = debounce(loadOptions, 300);

  const shareAction = useCallback(async () => {
    if (messageToShare.conversation == null || selectedOption?.value == null) return;
    try {
      await api.conversations.forwardMessage(messageToShare.conversation.id, messageToShare.id, {
        content: editorRef?.current?.getEditorContents() ?? { ops: [] },
        forwardToConversationId: selectedOption.value,
      });
      navigate(`/dashboard/rooms/${selectedOption.channel.room.id}/channel/${selectedOption.channel.id}`);
      closeModal("shareMessageModal");
    } catch (error) {
      toast.error(t("roomPage.tabs.room.conversation.forward.error"));
    }
  }, [selectedOption?.value]);

  const actions: IModalAction[] = useMemo(
    () => [
      {
        action: shareAction,
        label: t("roomPage.tabs.room.conversation.forward.buttons.share"),
        disabled: selectedOption?.value == null,
        type: "primary",
      },
    ],
    [selectedOption?.value],
  );

  const forwardMessage: IMessageForward = {
    content: messageToShare.content,
    id: messageToShare.id,
    files: messageToShare.files,
    originalMessage: messageToShare,
    forwardType: "default",
  };
  return (
    <>
      {messageToShare.conversation != null ? (
        <ModalTabComponent footer={<ModalFooterComponent actions={actions}>a</ModalFooterComponent>}>
          <>
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={debouncedLoadOptions}
              onChange={handleChange}
              styles={customStyles}
              menuPortalTarget={document.getElementById("global-wrapper")}
              components={{ DropdownIndicator: null, ClearIndicator }}
              placeholder={t("roomPage.tabs.room.conversation.forward.search.placeholder")}
              controlShouldRenderValue={!isSearchOpened}
              onMenuOpen={() => {
                setIsSearchOpened(true);
              }}
              onMenuClose={() => {
                setIsSearchOpened(false);
              }}
              formatOptionLabel={(option) => <ChannelOptionComponent option={option as IOption} />}
            />
            <h2 className="mb-2 mt-4 flex w-full items-center text-sm font-bold text-zinc-500 dark:text-dark-300">
              {t("roomPage.tabs.room.conversation.forward.titleToShare")}
            </h2>
            <ForwardedMessageComponent forward={forwardMessage} className="w-full" />
            <div className="mt-2">
              <RichTextEditorComponent
                contextType="forwardedMessage"
                contextId={messageToShare.conversation.id}
                isMessagePreview={false}
                ref={editorRef}
              />
            </div>
          </>
        </ModalTabComponent>
      ) : null}
    </>
  );
};
