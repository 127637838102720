import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { useCallback, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const useMessageThread = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const messageIdParam = searchParams.get("messageId");
  const { closeAllDrawers } = useContext(DrawerContext);

  const openThread = useCallback(
    (messageId: string) => {
      const params = messageIdParam != null ? `?messageId=${messageIdParam}` : "";
      navigate(`./thread/${messageId}${params}`);
      closeAllDrawers();
    },
    [messageIdParam],
  );

  return {
    openThread,
  };
};
