import { type IOrganisationMember, type IRoomMember } from "@/api/client";
import { ConfirmRoleChangeOrDeletionModalContent } from "@/components/header/profileDropdown/organisationModal/tabs/orgMembers/ConfirmRoleChangeOrDeletionModalContent";
import { ModalLayoutComponent } from "@/components/shared/modal/ModalLayoutComponent";
import { ModalNames } from "@/constants/modalNames";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { type IFormattedChannel } from "@/interfaces/channel";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const ConfirmRoleChangeOrDeletionModal = ({
  member,
  isDeletion = false,
  action,
  scope,
  isArchive = false,
  hasGuests = false,
}: {
  member: IOrganisationMember | IRoomMember | IFormattedChannel;
  isDeletion: boolean;
  action: (transferToId?: string) => Promise<void> | (() => void) | undefined;
  scope: "organisation" | "room" | "channel";
  isArchive?: boolean;
  hasGuests?: boolean;
}): JSX.Element => {
  const me = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const isMe = useMemo(() => {
    if (me == null) return false;
    return member.userId === me.id;
  }, [me]);

  const modalTitle = !isDeletion
    ? t(`members.${scope}.transferSuperAdminRights`)
    : isArchive
    ? t(`members.${scope}.leaveAndArchive`)
    : isMe && member?.role?.slug === `default-${scope}-owner`
    ? t(`members.${scope}.removeMe`)
    : t(`members.${scope}.removeUser`);
  return (
    <>
      <ModalLayoutComponent
        tabs={[
          {
            label: t("general.confirm"),
            content: (
              <ConfirmRoleChangeOrDeletionModalContent
                isDeletion={isDeletion}
                action={action}
                member={member}
                scope={scope}
                isArchive={isArchive}
                hasGuests={hasGuests}
              />
            ),
            id: "confirm",
            disabled: false,
          },
        ]}
        title={modalTitle}
        size="sm"
        name={ModalNames.CONFIRM_ROLE_CHANGE_OR_DELETION}
      ></ModalLayoutComponent>
    </>
  );
};
