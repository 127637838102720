export enum ModalNames {
  CONFIRM_GUEST_ROOM_CREATION = "confirmGuestRoomCreation",
  CONFIRM_MESSAGE_DELETION = "confirmMessageDeleltion",
  CONFIRM_CLOSE_CREATE_TASK_DRAWER = "confirmCloseCreateTaskDrawer",
  CONFIRM_TASK_DELETION = "confirmTaskDeletion",
  CONFIRM = "confirm",
  CONFIRM_LIMITATIONS = "confirmLimitations",
  PLANS = "plans",
  SUBSCRIPTION_UPDATED = "subscriptionUpdated",
  DOWNGRADE = "downgrade",
  CONFIRM_DOWNGRADE = "confirmDowngrade",
  CONFIRM_DOWNGRADE_FINISHED = "confirmDowngradeFinished",
  CONFIRM_ROOM_CREATION_DISABLING = "confirmRoomCreationDisabling",
  CONFIRM_ORG_ROOM_DISABLING = "confirmOrgRoomDisabling",
  CROP_IMAGE = "cropImage",
  CONFIRM_ROLE_CHANGE_OR_DELETION = "confirmRoleChangeOrDeletion",
  CHANNEL_MODAL = "channelModal",
  CREATE_ROOM = "createRoom",
  CREATE_CALENDAR_EVENT = "createCalendarEvent",
  ADD_CALENDAR_ACCOUNT = "addCalendarAccount",
  TAG_EDIT = "tagEdit",
  CONFIRM_TAG_DELETION = "confirmTagDeletion",
  ENHANCE_BY_AI = "enhanceByAI",
}
