import { DrawerWrapperComponent } from "@/components/shared/drawer/DrawerWrapperComponent";
import { SectionHeaderComponent } from "@/components/shared/layout/SectionHeaderComponent";
import { TabMenuComponent } from "@/components/shared/layout/TabMenuComponent";
import { useHomePageTabs } from "@/hooks/homePage/useHomePageTabs";
import { Outlet } from "react-router-dom";
export const HomePage = (): JSX.Element => {
  const { tabs } = useHomePageTabs();
  return (
    <>
      <section className={`flex w-full flex-1 overflow-hidden `}>
        <div className="flex w-full flex-col overflow-y-hidden bg-white">
          <SectionHeaderComponent className="px-layout py-0">
            <TabMenuComponent tabs={tabs} />
          </SectionHeaderComponent>

          <div className="h-full flex-1 overflow-y-scroll">
            <Outlet />
          </div>
        </div>
        <DrawerWrapperComponent />
      </section>
    </>
  );
};
