import { type IFile, type IRoomIdName } from "@/api/client";
import { useDocumentViewer } from "@/components/shared/documentViewer/useDocumentViewer";
import { useFilesQuery } from "@/hooks/queries/files/useFilesQuery";
import { type IChannelIdNameRoom } from "@/interfaces/channel";
import { debounce } from "@/utils/utilities";
import { useCallback, useMemo, useState } from "react";

export const useFilesContent = (roomId: string | undefined) => {
  const [searchText, setSearchText] = useState<string>("");
  const [filteredRoom, setFilteredRoom] = useState<IRoomIdName | null>(null);
  const [filteredChannel, setFilteredChannel] = useState<IChannelIdNameRoom | null>(null);
  const [filteredFileExtension, setFilteredFileExtension] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [imageToPreview, setImageToPreview] = useState<IFile | null>(null);
  const { isVisible: isImageViewerVisible, setIsVisible: setIsImageViewerVisible } = useDocumentViewer();
  const [pdfToPreview, setPdfToPreview] = useState<IFile | null>(null);
  const { isVisible: isPdfViewerVisible, setIsVisible: setIsPdfViewerVisible } = useDocumentViewer();

  const { data: filesData, fetchNextPage } = useFilesQuery({
    roomId: roomId ?? filteredRoom?.id,
    channelId: filteredChannel?.id,
    extension: filteredFileExtension ?? undefined,
    name: searchText,
  });

  const files = useMemo(() => {
    return filesData?.pages?.flatMap((page) => page.data) ?? [];
  }, [filesData]);

  const fileExtensions = useMemo(() => (filesData?.pages ?? [])[0]?.fileExtensions ?? [], [filesData]);

  const updateSearch = useCallback(
    debounce((value: string) => {
      setSearchText(value);
    }, 100),
    [],
  );

  const tryToFetchNextPage = useCallback(async () => {
    await fetchNextPage();
  }, []);

  const onFilterRoomChange = (room: IRoomIdName | null) => {
    setFilteredRoom((currentRoom) => {
      if (currentRoom?.id !== room?.id) {
        setFilteredChannel(null);
      }
      return room;
    });
  };

  const onFilterChannelChange = (channel: IChannelIdNameRoom | null) => {
    setFilteredChannel(channel);
    if (channel?.room != null) {
      setFilteredRoom(channel?.room);
    }
  };

  const onFilterFileExtensionChange = (fileExtension: string | null) => {
    setFilteredFileExtension(fileExtension);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(files.map((file) => file.id));
    }
    setSelectAll(!selectAll);
  };

  const onPreviewPdf = (file: IFile) => {
    setPdfToPreview(file);
    setIsPdfViewerVisible(true);
  };

  const onPreviewImage = (file: IFile) => {
    setImageToPreview(file);
    setIsImageViewerVisible(true);
  };

  const onClosePdfViewer = () => {
    setPdfToPreview(null);
    setIsPdfViewerVisible(false);
  };

  return {
    files,
    fileExtensions,
    updateSearch,
    tryToFetchNextPage,
    filteredRoom,
    filteredChannel,
    filteredFileExtension,
    selectedItems,
    selectAll,
    handleSelectAll,
    onFilterRoomChange,
    onFilterChannelChange,
    onFilterFileExtensionChange,
    setSelectAll,
    setSelectedItems,
    imageToPreview: isImageViewerVisible ? imageToPreview : null,
    pdfToPreview,
    isPdfViewerVisible,
    onClosePdfViewer,
    onPreviewImage,
    onPreviewPdf,
  };
};
