import { type IMessage } from "@/api/client";
import { ForwardMessageModalTabComponent } from "@/components/roomPage/tabs/conversation/message/forwardMessageModal/ForwardMessageModalTabComponent";
import { ModalLayoutComponent } from "@/components/shared/modal/ModalLayoutComponent";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IForwardMessageModalProps {
  messageToShare: IMessage;
}

export const ForwardMessageModalComponent = ({ messageToShare }: IForwardMessageModalProps): JSX.Element => {
  const { t } = useTranslation();

  const tab = useMemo(
    () => ({
      label: t("channelModal.tabs.about.label"),
      content: <ForwardMessageModalTabComponent messageToShare={messageToShare} />,
      id: "default",
      disabled: false,
    }),
    [],
  );

  return (
    <ModalLayoutComponent
      tabs={[tab]}
      title={t("roomPage.tabs.room.conversation.forward.title")}
      size="auto"
      name="shareMessageModal"
      defaultTab="default"
    ></ModalLayoutComponent>
  );
};
