import { type IChannelConversation, type IConversationMessagesSend, type IMessageForward } from "@/api/client";
import { eventNames } from "@/constants/eventNames";
import { QueryKeys } from "@/constants/queryKeys";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { RoomContext } from "@/contexts/RoomContext";
import { useDraft } from "@/hooks/shared/useDraft";
import { useEditMessageQueries } from "@/hooks/shared/useEditMessageQueries";
import { useFormatMessageForOptimistic } from "@/hooks/shared/useFormatMessageForOptimistic";
import { type ISendMutation } from "@/interfaces/richTextEditor";
import { addMessage as addMessageApi } from "@/services/ConversationService";
import { type InternalFile } from "@/types/fileTypes";
import { isDeltaContentEmpty } from "@/utils/utilities";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useSendMessageMutation = (
  contextType: string,
  contextId: string,
  conversation: IChannelConversation | undefined,
  files: InternalFile[],
  areFilesUploading: boolean,
  forwardMessage: IMessageForward | undefined,
) => {
  const { t } = useTranslation();
  const { replaceMessageIdInQuery } = useEditMessageQueries();
  const me = useContext(AuthenticationContext);
  const roomContext = useContext(RoomContext);
  const { saveDraft } = useDraft();
  const { formatMessageForOptimistic } = useFormatMessageForOptimistic(conversation, forwardMessage, files);

  return useMutation({
    mutationFn: async (data: ISendMutation) => {
      if (me == null || conversation == null) return;

      const isContentEmpty = isDeltaContentEmpty(data.content);

      if (isContentEmpty && files.length === 0) return;
      if (files.length > 0 && areFilesUploading) return toast.error(t("roomPage.tabs.room.conversation.message.toasts.waitForUploadError"));

      const newMessageForOptimistic = { ...formatMessageForOptimistic(data) };
      newMessageForOptimistic.forward = forwardMessage;

      const newMessageEvent = new CustomEvent(eventNames.NEW_MESSAGE, {
        detail: {
          message: newMessageForOptimistic,
        },
      });

      const resetMessageFileEvent = new CustomEvent(eventNames.RESET_MESSAGE_FILE, { detail: contextId });
      const resetMessageReplyEvent = new CustomEvent(eventNames.RESET_REPLY, { detail: contextId });

      window.dispatchEvent(newMessageEvent);
      window.dispatchEvent(resetMessageFileEvent);
      window.dispatchEvent(resetMessageReplyEvent);

      const messageForApi: IConversationMessagesSend = {
        content: newMessageForOptimistic.content,
        files: files?.map((file) => {
          return { id: file.id };
        }),
        sentFromRoomId: roomContext?.roomResponse?.room?.id,
        parentId: newMessageForOptimistic.parent?.id,
        forwardMessageId: forwardMessage?.id,
        forwardType: forwardMessage != null ? "reply" : undefined,
      };

      const newMessageResponse = await addMessageApi(messageForApi, conversation.id);
      saveDraft(contextId, null, true);

      const queryKeys = [QueryKeys[contextType.toUpperCase() as keyof typeof QueryKeys], contextId, QueryKeys.MESSAGES];
      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      void replaceMessageIdInQuery(queryKeys, newMessageResponse, newMessageForOptimistic.id);
      // add the member in the sender object of the response
      if (newMessageResponse.sender == null) return;
      newMessageResponse.sender.user = me;
    },
  });
};
