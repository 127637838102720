import { type FunctionComponent } from "react";
import { RiCloseFill as CloseIcon } from "react-icons/ri";

interface DrawerHeaderComponentProps {
  title: string;
  onCloseDrawer: () => void;
}

export const DRAWER_HEADER_HEIGHT = 56;

export const DrawerHeaderComponent: FunctionComponent<DrawerHeaderComponentProps> = ({ title, onCloseDrawer }) => {
  return (
    <div className="flex w-full flex-row items-center justify-between border-b bg-dark-25 px-6" style={{ height: DRAWER_HEADER_HEIGHT }}>
      <h1 className="font-semibold text-zinc-800">{title}</h1>
      <CloseIcon className="h-5 w-5 cursor-pointer" onClick={onCloseDrawer} />
    </div>
  );
};
