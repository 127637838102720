import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { useContext } from "react";

export const DrawerWrapperComponent = (): JSX.Element => {
  const { openedDrawers, isOpened, isAnimationDisabled } = useContext(DrawerContext);

  return (
    <div
      className={`h-full w-[36rem] flex-col ${
        isAnimationDisabled ? "" : "transition-all duration-300 "
      }z-40 border-l dark:border-r-dark-700 dark:bg-dark-900`}
      style={{
        marginRight: openedDrawers.length > 0 && isOpened ? "-0%" : "-100%",
      }}
    >
      {openedDrawers.length > 0 ? openedDrawers[openedDrawers.length - 1].component : null}
    </div>
  );
};
