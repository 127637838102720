import { eventNames } from "@/constants/eventNames";
import { type InternalFile } from "@/types/fileTypes";
import { useCallback, useEffect, useState } from "react";

export const useFileUpload = (contextType: string, contextId: string) => {
  const [files, setFiles] = useState<InternalFile[]>([]);
  const [areFilesUploading, setAreFilesUploading] = useState<boolean>(false);
  const handleFileIdsUpdated = useCallback(
    (event: CustomEvent) => {
      if (event.detail.contextId !== contextId) return;
      setFiles(event.detail.fileData);
    },
    [setFiles, files],
  );
  const handleFileStatusesUpdated = useCallback(
    (event: CustomEvent) => {
      if (event.detail.contextId !== contextId) return;
      setAreFilesUploading(event.detail.isUploading);
    },
    [areFilesUploading, setAreFilesUploading],
  );

  useEffect(() => {
    if (contextType === "conversation" || contextType === "message" || contextType === "taskComment") {
      window.addEventListener(eventNames.FILE_DATA_UPDATED, handleFileIdsUpdated as EventListener);
      window.addEventListener(eventNames.FILE_UPLOAD_STATUS_UPDATED, handleFileStatusesUpdated as EventListener);
    }
    return () => {
      if (contextType === "conversation" || contextType === "message" || contextType === "taskComment") {
        window.removeEventListener(eventNames.FILE_DATA_UPDATED, handleFileIdsUpdated as EventListener);
        window.removeEventListener(eventNames.FILE_UPLOAD_STATUS_UPDATED, handleFileStatusesUpdated as EventListener);
      }
    };
  }, [handleFileStatusesUpdated]);

  return { files, areFilesUploading };
};
