export enum HttpExceptionsEnum {
  BAD_REQUEST = "bad_request",
  UNAUTHORIZED = "unauthorized",
  FORBIDDEN = "forbidden",
  NOT_FOUND = "not_found",
  USER_MUST_BE_FIRST_ADDED_IN_ORGANISATION = "user_must_be_first_added_in_organisation",
  CONFLICT = "conflict",
  USER_EXISTING_WITH_OTHER_PROVIDER = "user_existing_with_other_provider",
}

export enum HttpExceptionsBadRequestMessagesEnum {
  DEFAULT = "unknown_constraint",
  NOT_NULL_OR_UNDEFINED = "not_null_or_undefined",
  UNIQUE_VALUE_ALREADY_TAKEN = "unique_value_already_taken",
  INCORRECT_FORMAT = "incorrect_format",
  INFERIOR_THAN_MINIMUM_VALUE = "inferior_than_minimum_value",
  SUPERIOR_THAN_MAXIMUM_VALUE = "superior_than_maximum_value",
  INFERIOR_THAN_MINIMUM_LENGTH = "inferior_than_minimum_length",
  SUPERIOR_THAN_MAXIMUM_LENGTH = "superior_than_maximum_length",
  ACTION_REQUIRED = "action_required",
  NOT_CONTAINS_UPPERCASE = "not_contains_uppercase",
  NOT_CONTAINS_LOWERCASE = "not_contains_lowercase",
  NOT_CONTAINS_INTEGER = "not_contains_integer",
  NOT_CONTAINS_SPECIAL_CHARACTER = "not_contains_special_character",
  SLUG_NOT_ALLOWED = "slug_not_allowed",
  INCORRECT_VALUE = "incorrect_value",
  DOMAIN_INVALID = "domain_invalid",
  USER_EXISTING_WITH_OTHER_PROVIDER = "user_existing_with_other_provider",
  GOOGLE_AUTHENTICATION_REQUIRED = "google authentication required",
  USER_EXISTING_IN_OTHER_ORGANISATION = "user_existing_in_other_organisation",
}
