import type { IEmail } from "@/api/client";
import { EmailHistoryComponent } from "@/components/roomPage/tabs/emails/emailHistory/EmailHistoryComponent";
import { EmailRecipientsComponent } from "@/components/roomPage/tabs/emails/EmailRecipientsComponent";
import { EmailReplyButtonComponent } from "@/components/roomPage/tabs/emails/emailReply/EmailReplyButtonComponent";
import { useEmail } from "@/components/roomPage/tabs/emails/useEmail";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { EmailThreadContext } from "@/contexts/EmailThreadContextProvider";
import { useDateFormat } from "@/hooks/shared/date/useDateFormat";
import { useContext, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronDown } from "react-icons/bi";

interface IEmailComponentProps {
  email: IEmail;
}

export const EmailComponent: FunctionComponent<IEmailComponentProps> = ({ email }) => {
  const { t } = useTranslation();
  const { namesOrEmailsFromRecipients, isVisible, setIsVisible, onTooltipClose, senderName, isHighlighted, emailIdParam } = useEmail(email);
  const { replyToMailId } = useContext(EmailThreadContext);
  const { formatDateForEmail } = useDateFormat();
  return (
    <div className="pr-4 pt-3">
      <div
        key={`${email.id}${emailIdParam}`}
        className={`group/email relative rounded-lg border pt-3 text-sm ${replyToMailId === email.id ? "border-sky-500" : ""} ${
          isHighlighted ? "border-sky-500" : ""
        }`}
      >
        <div className="flex justify-between">
          <div>
            <div className="flex p-3 pt-0 font-semibold">
              <div className="mr-2 h-8 w-8">
                <UserAvatarComponent userName={senderName} size="md" className="" />
              </div>
              <div>
                <div className="-mt-1">{senderName}</div>
                <TooltipComponent
                  visible={isVisible}
                  onClickOutside={onTooltipClose}
                  interactive
                  content={<EmailRecipientsComponent email={email} />}
                  placement="bottom-start"
                  maxWidth={1000}
                >
                  <button
                    className="flex items-center truncate"
                    onClick={() => {
                      setIsVisible(true);
                    }}
                  >
                    <div className="mb-0 flex items-center pr-3 text-xs text-zinc-400">
                      <span className="mr-1">{t("roomPage.tabs.mails.to")} </span>{" "}
                      <span className="font-semibold">{namesOrEmailsFromRecipients}</span>
                      <BiChevronDown className="" />
                    </div>
                  </button>
                </TooltipComponent>
              </div>
            </div>
          </div>
          <div className="absolute right-0 flex overflow-hidden">
            <div className="mr-4 text-xs text-zinc-400 transition-all group-hover/email:mr-16">{formatDateForEmail(email.createdAt)}</div>
            <div className="absolute -right-full self-start opacity-0 transition-all group-hover/email:right-0 group-hover/email:opacity-100">
              <EmailReplyButtonComponent email={email} onlyIcons />
            </div>
          </div>
        </div>
        <div className="whitespace-pre-wrap break-words p-3 pt-1">{email.bodyText.visible.trim()}</div>
        <EmailHistoryComponent history={email.bodyText.quoted} />
      </div>
    </div>
  );
};
