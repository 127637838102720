import { type IMessage } from "@/api/client";
import { useMessageActionsMenu } from "@/components/roomPage/tabs/conversation/useMessageActionsMenu";
import { useMessageActionsMenuAnimation } from "@/components/roomPage/tabs/conversation/useMessageActionsMenuAnimation";
import { EmojiPickerForReactionsComponent } from "@/components/shared/conversation/EmojiPickerForReactionsComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { ConversationContext } from "@/contexts/ConversationContext";
import { PreferencesContext } from "@/hooks/shared/usePreferences";
import { useReaction } from "@/hooks/shared/useReaction";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsPinAngleFill as PinFillIcon, BsPinAngle as PinIcon } from "react-icons/bs";
import { CgSmileMouthOpen as ReactionIcon } from "react-icons/cg";
import { FaTrash as TrashIcon } from "react-icons/fa";
import { IoIosShareAlt as ArrowIcon } from "react-icons/io";
import { RiPencilFill as PencilIcon, RiReplyLine as ReplyIcon, RiTaskLine as TaskIcon } from "react-icons/ri";
export const MessageActionsMenuComponent = ({
  message,
  onEdit,
  isInThread = false,
}: {
  message: IMessage;
  onEdit: () => void;
  isInThread?: boolean;
}): JSX.Element => {
  const { preferences } = useContext(PreferencesContext);
  const { member } = useContext(ConversationContext);
  const { t } = useTranslation();
  const { setIsEmojiPickerOpen, isEmojiPickerOpen, openEmojiPicker, pickerPosition } = useReaction();
  const {
    deleteMessage,
    shouldShowThreadButton,
    openForwardMessageModal,
    is1to1Conversation,
    startReply,
    pinOrUnPinMessage,
    canPinMessage,
    openThread,
    openTaskDrawer,
  } = useMessageActionsMenu(message);

  const { menuRef } = useMessageActionsMenuAnimation();

  return (
    <div
      ref={menuRef}
      key={message.id}
      className="absolute top-1 z-20 flex w-full -translate-y-1/2 whitespace-nowrap px-4 opacity-0 transition-all duration-200"
    >
      {isEmojiPickerOpen && (
        <EmojiPickerForReactionsComponent
          message={message}
          position={pickerPosition}
          isDark={preferences.darkMode === true}
          closeEmojiPicker={() => {
            setIsEmojiPickerOpen(false);
          }}
        />
      )}
      <div className="ml-auto flex h-auto min-w-max rounded-md border border-pigeon-post-200 bg-white px-2 dark:border-dark-700 dark:bg-dark-800">
        {!isInThread && shouldShowThreadButton && (
          <TooltipComponent content={t("roomPage.tabs.room.conversation.message.tooltips.actionMenu.thread")} placement="top">
            <button>
              <ArrowIcon className="m-1 h-3.5 w-3.5 -scale-x-100 text-zinc-500 hover:text-sky-600 dark:text-dark-300" onClick={openThread} />
            </button>
          </TooltipComponent>
        )}
        {is1to1Conversation && (
          <TooltipComponent content={t("roomPage.tabs.room.conversation.message.tooltips.actionMenu.reply")} placement="top">
            <button>
              <ReplyIcon className="m-1 h-3.5 w-3.5 text-zinc-500 hover:text-sky-600 dark:text-dark-300" onClick={startReply} />
            </button>
          </TooltipComponent>
        )}
        <TooltipComponent content={t("roomPage.tabs.room.conversation.message.tooltips.actionMenu.react")} placement="top">
          <button onClick={openEmojiPicker}>
            <ReactionIcon className="m-1 h-3.5 w-3.5 text-zinc-500 hover:text-sky-600 dark:text-dark-300" />
          </button>
        </TooltipComponent>
        <TooltipComponent content={t("roomPage.tabs.room.conversation.message.tooltips.actionMenu.forward")} placement="top">
          <button onClick={openForwardMessageModal}>
            <ArrowIcon className="m-1 h-3.5 w-3.5 text-zinc-500 hover:text-sky-600 dark:text-dark-300" />
          </button>
        </TooltipComponent>
        {canPinMessage ? (
          <TooltipComponent content={t("roomPage.tabs.room.conversation.message.tooltips.actionMenu.save")} placement="top">
            <button onClick={pinOrUnPinMessage}>
              {message?.pinned === true ? (
                <PinFillIcon className="m-1 h-3.5 w-3.5 text-sky-600 hover:text-sky-600 dark:text-dark-300" />
              ) : (
                <PinIcon className="m-1 h-3.5 w-3.5 text-zinc-500 hover:text-sky-600 dark:text-dark-300" />
              )}
            </button>
          </TooltipComponent>
        ) : null}

        <TooltipComponent content={t("roomPage.tabs.room.conversation.message.tooltips.actionMenu.createTaskFromMessage")} placement="top">
          <button onClick={openTaskDrawer}>
            <TaskIcon className="m-1 h-3.5 w-3.5 text-zinc-500 hover:text-sky-600 dark:text-dark-300" />
          </button>
        </TooltipComponent>

        {member?.user?.id === message?.sender?.user?.id && !message?.id.startsWith("OPTIMISTIC_ID") && (
          <>
            <TooltipComponent content={t("roomPage.tabs.room.conversation.message.tooltips.actionMenu.edit")} placement="top">
              <button onClick={onEdit}>
                <PencilIcon className="m-1 h-3.5 w-3.5 text-zinc-500 hover:text-sky-600 dark:text-dark-300" />
              </button>
            </TooltipComponent>
            <TooltipComponent content={t("roomPage.tabs.room.conversation.message.tooltips.actionMenu.delete")} placement="top">
              <button onClick={deleteMessage}>
                <TrashIcon className="m-1 h-3.5 w-2.5 text-zinc-500 hover:text-sky-600 dark:text-dark-300" />
              </button>
            </TooltipComponent>
          </>
        )}
      </div>
    </div>
  );
};
