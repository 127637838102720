import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { eventNames } from "@/constants/eventNames";
import { BulkInviteContext } from "@/contexts/BulkInviteContextProvider";
import { isMailValid } from "@/utils/utilities";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiMailSend as SendMailIcon } from "react-icons/bi";
import { IoClose as DenyIcon } from "react-icons/io5";

interface IInviteMemberComponent {
  email: string;
  member?: { firstName?: string; lastName?: string; email: string };
  isInvited?: boolean;
  inviteAction: () => void;
  inviteLabel?: string;
  isInBulk?: boolean;
}
export const InviteMemberComponent = ({
  email,
  member,
  isInvited = false,
  inviteAction,
  inviteLabel = "",
  isInBulk = false,
}: IInviteMemberComponent): JSX.Element => {
  const { t } = useTranslation();
  const inviteMember = useCallback(() => {
    if (!isMailValid(email)) {
      return;
    }
    window.dispatchEvent(new CustomEvent(eventNames.RESET_SEARCH));
    inviteAction();
  }, [email]);

  const { removeMail } = useContext(BulkInviteContext);

  return (
    <>
      <div className="flex w-full items-center border-b px-6 py-2 last:mb-2 last:border-0 hover:bg-gray-100 dark:hover:bg-dark-800">
        {member != null ? (
          <UserAvatarComponent userName={`${member.firstName as string} ${member.lastName as string}`} className="mr-2 h-7" />
        ) : (
          <i className="mr-2 flex h-7 w-7 items-center justify-center rounded-md bg-zinc-200 p-1">
            <SendMailIcon className="aspect-square w-10/12 text-zinc-600" />
          </i>
        )}

        {member != null ? (
          <span className="truncate text-sm dark:text-dark-300">
            {member.firstName} {member.lastName} <span className="text-zinc-400 dark:text-zinc-500"> ({member.email})</span>
          </span>
        ) : (
          <span className="text-sm dark:text-dark-300">{email}</span>
        )}
        {!isInvited && !isInBulk && (
          <ButtonComponent
            status="secondary"
            size="sm"
            className="ml-auto border-0 font-[600] dark:bg-dark-700 dark:text-dark-300 dark:hover:bg-dark-600"
            onClick={() => {
              inviteMember();
            }}
            disabled={!isMailValid(email)}
          >
            {inviteLabel === "" ? t("members.invite") : inviteLabel}
          </ButtonComponent>
        )}

        {!isInvited && isInBulk && (
          <button
            type="button"
            onClick={() => {
              removeMail(email);
            }}
            className="ml-auto h-5 w-5 cursor-pointer opacity-60 transition-opacity hover:opacity-100"
          >
            <div className="h-full w-full opacity-80 transition-opacity hover:opacity-100">
              <DenyIcon />
            </div>
          </button>
        )}
      </div>
    </>
  );
};
