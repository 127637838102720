import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { ImageComponent } from "@/components/shared/image/ImageComponent";
import { usePictureUploader } from "@/components/shared/uploader/pictureUploader/usePictureUploader";
import "filepond/dist/filepond.min.css";
import { useCallback } from "react";
import { FilePond } from "react-filepond";
import { useTranslation } from "react-i18next";
import { BsArrowClockwise as LoadingIcon } from "react-icons/bs";
import { SlCloudUpload as UploadIcon } from "react-icons/sl";
interface IPictureUploaderComponentProps {
  pictureUrl?: string;
  context?: string;
  contextId?: string;
  canUpdate?: boolean;
}

export const PictureUploaderComponent = ({
  pictureUrl,
  context = "profile",
  contextId,
  canUpdate = true,
}: IPictureUploaderComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { filepondRef, isUploading, onAddFile, onErrorHandler } = usePictureUploader(context, contextId);
  const handlePictureClick = useCallback(() => {
    if (filepondRef.current == null) return;
    filepondRef.current.browse();
  }, [filepondRef.current]);

  return (
    <>
      <div
        className={`group/profile-picture relative flex h-full w-full items-center justify-center ${
          canUpdate && pictureUrl == null ? "cursor-pointer" : ""
        }`}
      >
        {filepondRef.current != null && canUpdate && pictureUrl == null && (
          <button type="button" className={`absolute z-20 flex aspect-square w-full items-center justify-center `} onClick={handlePictureClick}>
            <div
              className={`flex aspect-square w-full flex-col items-center justify-center rounded-xl border-2 border-dashed border-transparent bg-white transition-all ${
                pictureUrl != null
                  ? "w-[85%] border-zinc-700 bg-white/0 text-zinc-700 opacity-0 group-hover/profile-picture:bg-white/50 group-hover/profile-picture:opacity-100"
                  : "border-zinc-300 text-zinc-300 group-hover/profile-picture:border-zinc-400 group-hover/profile-picture:text-zinc-400"
              }`}
            >
              <UploadIcon className="h-6 w-6" />
              <span>{t("header.editProfile.modal.tabs.profile.uploadProfilePicture")}</span>
            </div>
          </button>
        )}
        <LoadingIcon
          className={`pointer-events-none absolute z-50 h-4 w-4 animate-spin transition-opacity ${isUploading ? "opacity-100" : "opacity-0"}`}
        />
        <ImageComponent
          key={pictureUrl}
          src={pictureUrl ?? ""}
          alt={t("header.editProfile.modal.tabs.profile.profilePicture")}
          className="h-32 w-32 rounded-xl border border-dark-100"
        />
      </div>
      {pictureUrl != null ? (
        <ButtonComponent status="secondary" className="mt-2" align="center" size="sm" loading={isUploading} onClick={handlePictureClick}>
          {t("pictureUploader.changePicture")}
        </ButtonComponent>
      ) : null}
      <div className="hidden">
        <FilePond ref={filepondRef} acceptedFileTypes={["image/jpeg", "image/png"]} credits={false} onerror={onErrorHandler} onaddfile={onAddFile} />
      </div>
    </>
  );
};
