import { type IOrganisationMember, type IRole, type IRoomMember } from "@/api/client";
import { ConfirmRoleChangeOrDeletionModal } from "@/components/header/profileDropdown/organisationModal/tabs/orgMembers/ConfirmRoleChangeOrDeletionModal";
import { CustomSelectComponent } from "@/components/shared/form/select/CustomSelectComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { ModalNames } from "@/constants/modalNames";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { type IFormattedChannel } from "@/interfaces/channel";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdPersonRemove as DenyIcon } from "react-icons/md";

interface IMemberProps {
  member: IOrganisationMember | IFormattedChannel | IRoomMember;
  isDisabled?: boolean;
  scope: "organisation" | "room" | "channel";
  leaveMember: (transferToId?: string) => Promise<void> | ((() => void) | undefined);
  getMembersRoleOptions: (scopeId?: string, membershipId?: string | number) => Promise<IRole[]>;
  setMemberRole: (role: IRole) => void;
  shouldOpenConfirmationModal?: boolean;
  isLast?: boolean;
  hasGuests?: boolean;
  canChangeRole?: boolean;
  canRemove?: boolean;
}

// TODO: Needs to be refactored. This is a mess
export const MemberComponent = ({
  member,
  isDisabled = false,
  scope,
  leaveMember,
  getMembersRoleOptions,
  setMemberRole,
  isLast = false,
  hasGuests = false,
  canRemove = false,
  canChangeRole = false,
}: IMemberProps): JSX.Element => {
  const me = useContext(AuthenticationContext);
  const isMe = me?.id === member.userId;
  const scopeIdKey: "channelId" | "organisationId" | "roomId" = `${scope}Id`;
  // @ts-expect-error the key is dynamic
  const scopeId = member[scopeIdKey];

  const { t } = useTranslation();

  const [rolesByMember, setRolesByMember] = useState<Record<string, IRole[]>>({});

  // Open modal to confirm the deletion of a member
  const openDeleteMemberModal = useCallback((): void => {
    if (me == null) return;
    openModal(
      ModalNames.CONFIRM_ROLE_CHANGE_OR_DELETION,
      <ConfirmRoleChangeOrDeletionModal
        member={member}
        isDeletion={true}
        action={leaveMember}
        scope={scope}
        isArchive={isLast}
        hasGuests={hasGuests}
      />,
      0.2,
    );
  }, [me, isLast, hasGuests]);

  // Open modal to confirm the role change of a member
  const openChangeRoleConfirmationModal = useCallback((isDeletion = false, role: IRole | null = null): void => {
    if (me == null || role == null) return;
    const action = async () => {
      setMemberRole(role);
    };

    if (role.type === "owner") {
      openModal(
        ModalNames.CONFIRM_ROLE_CHANGE_OR_DELETION,
        <ConfirmRoleChangeOrDeletionModal member={member} isDeletion={false} action={action} scope={scope} />,
        0.2,
      );
    }
  }, []);

  const updateRolesByMember = useCallback(
    async (membershipId: string | number) => {
      if (rolesByMember[membershipId] !== undefined || me === undefined) return;
      const response = await getMembersRoleOptions(scopeId, membershipId);
      setRolesByMember({ ...rolesByMember, ...{ [membershipId]: response } });
    },
    [rolesByMember, me],
  );
  const computeRoleOptions = useCallback(
    (userId: string | number) => {
      if (rolesByMember?.[userId] == null) return;
      return rolesByMember?.[userId]?.map((role) => ({
        value: role,
        label: t(`defaultRoles.${role.slug}`),
      }));
    },
    [rolesByMember],
  );
  const handleRoleChange = useCallback(
    async (role: IRole) => {
      if (me === undefined) return;

      if (role.type === "owner") {
        openChangeRoleConfirmationModal(false, role);
        return;
      }

      setMemberRole(role);
    },
    [me],
  );

  return (
    <div className="group flex w-full items-center border-b px-6 py-2 transition-all last:border-0" key={member.userId}>
      <UserAvatarComponent
        userName={`${member.firstName ?? ""} ${member.lastName ?? ""}`}
        className="mr-2 h-7"
        userId={member.userId}
        key={member.userId}
      />
      <div className="text-sm dark:text-dark-300">
        {member.firstName} {member.lastName} {isMe && <span className="font-semibold text-sky-600">{t("general.you")}</span>}
        <span className="text-zinc-400 dark:text-zinc-500"> ({member.email})</span>
      </div>
      <div className="ml-auto flex items-center">
        {member.role?.slug != null && (
          <CustomSelectComponent<IRole>
            placeholder={t(`defaultRoles.${member.role?.slug ?? ""}`)}
            options={computeRoleOptions(member.id)}
            onChange={async (e) => {
              if (e?.value == null) {
                return;
              }
              await handleRoleChange(e?.value);
            }}
            onFocus={async () => {
              await updateRolesByMember(member.id);
            }}
            isDisabled={!canChangeRole}
            className="whitespace-nowrap"
          ></CustomSelectComponent>
        )}
        <button
          style={{
            backgroundColor: "transparent",
          }}
          className={`ml-2 border-0 font-[600] text-red-600 opacity-0 transition-opacity group-hover:opacity-70 ${
            !canRemove ? "pointer-events-none" : ""
          }`}
        >
          <div className="h-6 w-6">
            {canRemove && (
              <div onClick={openDeleteMemberModal} className="h-full w-full opacity-80 transition-opacity hover:opacity-100">
                <DenyIcon className="h-full w-full" />
              </div>
            )}
          </div>
        </button>
      </div>
    </div>
  );
};
