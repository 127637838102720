import "@/assets/styles/datePicker.scss";
import "react-datepicker/dist/react-datepicker.css";

import { TaskRoomColumnComponent } from "@/components/roomPage/tabs/tasks/TaskRoomColumnComponent";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { TaskAssigneesComponent } from "@/components/shared/tasks/TaskAssigneesComponent/TaskAssigneesComponent";
import { TaskPriorityComponent } from "@/components/shared/tasks/TaskPriorityComponent/TaskPriorityComponent";
import { useHomeTasksContent } from "@/components/shared/tasksContent/useHomeTasksContent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IoClose as ResetIcon } from "react-icons/io5";
import { MdOutlineTaskAlt as AddIcon } from "react-icons/md";

interface IHomeTasksContentComponents {
  roomId?: string;
}
export const HomeTasksContentComponent: FunctionComponent<IHomeTasksContentComponents> = ({ roomId }) => {
  const { t } = useTranslation();
  const { tasks, onCreateTask, filteredPriority, filteredAssigneeId, onFilterPriorityChange, rooms, onUserChange, onResetFilters } =
    useHomeTasksContent();

  if (rooms == null) return null;

  return (
    <>
      <nav className="flex h-14 items-center justify-between px-7">
        <div className="mr-2 flex items-center">
          <div className="mr-2">
            <TaskPriorityComponent priority={filteredPriority} displayNoPriority onPriorityChange={onFilterPriorityChange} isSelector />
          </div>
          {roomId == null ? (
            <div className="rounded-md border border-zinc-200 bg-zinc-100 px-2 py-1">
              <TaskAssigneesComponent
                onUserChange={onUserChange}
                assignedUsers={filteredAssigneeId != null ? [{ id: filteredAssigneeId }] : []}
                taskId={null}
                roomId={null}
                channelId={null}
                size="md"
                noAssigneeValue={t("tasks.assignee.filter")}
              />
            </div>
          ) : null}
        </div>
        <div className="flex">
          <ButtonComponent onClick={onCreateTask} status="secondary" size="sm" icon={<AddIcon />}>
            {t("tasks.createButton")}
          </ButtonComponent>
        </div>
      </nav>
      <section className="z-20 flex h-full w-full flex-row overflow-x-scroll border-t border-zinc-200 px-3">
        {roomId == null && rooms.length != null && rooms.length > 0 ? (
          rooms.map((room) => {
            return <TaskRoomColumnComponent key={room.id} room={room} tasks={tasks} />;
          })
        ) : (
          <div className="flex w-full flex-col items-center justify-center space-y-1 text-sm text-zinc-700">
            <span>{t("tasks.noResult")}</span>
            <ButtonComponent size="xs" status="secondary" icon={<ResetIcon />} onClick={onResetFilters}>
              {t("tasks.resetFilters")}
            </ButtonComponent>
          </div>
        )}
      </section>
    </>
  );
};
