// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface IRefreshToken {
  /**
   * long-ttl accessToken used to refresh short-ttl access_token, formatted as a string jwt
   * @example "jwt.xxx.xxx"
   */
  refreshToken: string;
}

export interface IApiAuthRefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

export interface IBadRequestError {
  /** @example 400 */
  statusCode: number;
  /** @example "bad_request" */
  message: string;
  /** @example {"email":["unique_value_already_taken","incorrect_format"],"phone":["incorrect_format"]} */
  errors: object;
}

export interface IForbiddenError {
  /** @example 403 */
  statusCode: number;
  /** @example "action_required" */
  message: string;
  /** @example {"action":["user_account_validation_self"],"display":["invalid_credentials"],"password":["not_null_or_undefined"]} */
  errors: object;
}

export interface IUtm {
  source?: string;
  medium?: string;
  campaign?: string;
  content?: string;
  term?: string;
}

export interface IAuthRegisterLogin {
  /** @example "test1@example.com" */
  email: string;
  /** @example "superSecretV4lue!" */
  password: string;
  /**
   * @default "EMAIL"
   * @example "EMAIL"
   */
  provider?: "EMAIL" | "GOOGLE" | "APPLE";
  /** @example "John" */
  firstName?: string;
  /** @example "Doe" */
  lastName?: string;
  /**
   * hash sent in case of person being invited to join an organisation
   * @example "aaaa-azerare-fgsdf"
   */
  hash?: string;
  utm?: IUtm;
}

export interface IImage {
  width: number;
  height: number;
}

export interface IAudio {
  duration: number;
}

export interface IFile {
  id: string;
  name: string;
  extension: string;
  url: string;
  createdAt: string;
  image?: IImage;
  audio?: IAudio;
  fileSizeInBytes?: number;
  type: "default" | "voice-note" | "profile-picture" | "email-attachment";
}

export enum SubscriptionStatus {
  ToBeValidated = "to_be_validated",
  Active = "active",
  PastDue = "past_due",
  Unpaid = "unpaid",
  Canceled = "canceled",
  LimitsSurpassedBlocked = "limits_surpassed_blocked",
}

export interface IPlanSummary {
  /** @example "01H3VZ96DVB4AW0AGMQ5G74CN2" */
  id: string;
  name?: string;
}

export interface ISubscriptionSummary {
  /** @example "01H3VZ96DVB4AW0AGMQ5G74CN2" */
  id: string;
  /** @example "active" */
  status: SubscriptionStatus;
  plan?: IPlanSummary;
  isUpgrade?: boolean;
  freeTrialEnd?: object;
}

export interface IUserContactInfo {
  /** @example "ASFLKVJJASIOJD" */
  id?: string;
  /** @example "test1@example.com" */
  email?: string;
  /** @example "John" */
  firstName?: string;
  /** @example "Doe" */
  lastName?: string;
}

export enum RoomTypeEnum {
  Organisation = "organisation",
  Project = "project",
}

export interface IRoomBasicInfo {
  id: string;
  name?: string;
  type: RoomTypeEnum;
  isHidden: boolean;
}

export enum ContextType {
  Organisation = "organisation",
  Room = "room",
  Conversation = "conversation",
  Channel = "channel",
}

export enum RoleType {
  Owner = "owner",
  Admin = "admin",
  User = "user",
  Guest = "guest",
  Custom = "custom",
}

export interface IAbility {
  method: string;
  slug: string;
  context: string;
}

export interface IRole {
  /** @example 1 */
  id: string;
  /** @example "administrator" */
  slug: string;
  contextType: ContextType;
  type: RoleType;
  abilities?: IAbility[];
}

export interface IOrganisationMember {
  /** @example 1 */
  id: string;
  /** @example "confirmed" */
  status: string;
  /** @example 1 */
  userId: string;
  /** @example "ada-lovelace" */
  userSlug: string;
  userProfilePicture: IFile;
  /** @example "ada-lovelace@gmail.com" */
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  /** @example "CEO" */
  position?: string;
  /** @example 1 */
  organisationId: string;
  /** @example "ada-lovelace" */
  organisationSlug: string;
  organisationName?: string;
  organisationType?: "personal" | "organisation";
  organisationSubscription?: ISubscriptionSummary;
  organisationOwner?: IUserContactInfo;
  organisationRoom?: IRoomBasicInfo;
  /** @example 1 */
  roleId: string;
  /** @example "slug" */
  roleSlug: string;
  roleType: string;
  role?: IRole;
  /** @example "2022-09-28T11:49:48.119Z" */
  createdAt: string;
  /** @example "2022-09-28T11:49:48.119Z" */
  updatedAt: string;
}

export interface INotificationSettings {
  mention: boolean;
  directMessage: boolean;
  threadReply: boolean;
  channelMessage: boolean;
  reaction: boolean;
}

export interface IUser {
  /** @example "2022-09-28T11:49:48.119Z" */
  createdAt: string;
  /** @example "2022-09-28T11:49:48.119Z" */
  updatedAt: string;
  /** @example 1 */
  id: string;
  /** @example "firstname-lastname" */
  slug: string;
  /** @example "test1@example.com" */
  email: string;
  linkedGmailEmail?: string;
  /**
   * @default "pending_self"
   * @example "pending_self"
   */
  status: string;
  /**
   * @default "EMAIL"
   * @example "EMAIL"
   */
  provider: "EMAIL" | "GOOGLE" | "APPLE";
  /** @example "John" */
  firstName?: string;
  /** @example "Doe" */
  lastName?: string;
  /** @example "+330011223344" */
  phone?: string;
  /** @example "CEO" */
  position?: string;
  organisations?: IOrganisationMember[];
  mainOrganisation?: IOrganisationMember;
  profilePicture?: IFile;
  notificationSettings: INotificationSettings;
  owner: object;
}

export interface IAuthBaseResponse {
  operation: string;
  user: IUser;
  accessToken: string;
  refreshToken: string;
}

export interface ApiUnprocessableEntityExceptionDetailsOrganisation {
  domain: string;
}

export interface ApiUnprocessableEntityExceptionDetails {
  organisation: ApiUnprocessableEntityExceptionDetailsOrganisation;
}

export interface ApiUnprocessableEntityException {
  statusCode:
    | 100
    | 101
    | 102
    | 103
    | 200
    | 201
    | 202
    | 203
    | 204
    | 205
    | 206
    | 300
    | 301
    | 302
    | 303
    | 304
    | 307
    | 308
    | 400
    | 401
    | 402
    | 403
    | 404
    | 405
    | 406
    | 407
    | 408
    | 409
    | 410
    | 411
    | 412
    | 413
    | 414
    | 415
    | 416
    | 417
    | 418
    | 421
    | 422
    | 424
    | 428
    | 429
    | 500
    | 501
    | 502
    | 503
    | 504
    | 505;
  message:
    | "mail_domain_owned_by_organisation"
    | "feature_not_supported_cannot_register_with_public_domain"
    | "user_must_be_first_restored_in_organisation"
    | "user_must_be_confirmed_in_organisation"
    | "user_is_self_pending_validation"
    | "user_must_be_first_added_in_organisation";
  details: ApiUnprocessableEntityExceptionDetails;
}

export interface IAuthConfirmEmail {
  /**
   * Hash received via a 2FA or email
   * @example "hash_value"
   */
  hash: string;
}

export interface IAuthConfirmEmailResponse {
  success: boolean;
  redirect: "login" | "wait-for-admin-approval";
}

export interface INotFoundError {
  /** @example 404 */
  statusCode: number;
  /** @example "content_not_found" */
  message: string;
  /** @example {"email":["unique_value_already_taken","incorrect_format"],"phone":["incorrect_format"]} */
  errors: object;
}

export interface IAuthEmailLogin {
  /** @example "test1@example.com" */
  email: string;
  /** @example "superSecretV4lue" */
  password: string;
}

export interface IUnauthorizedError {
  /** @example 401 */
  statusCode: number;
  /** @example "action_required" */
  message: string;
  /** @example {"action":["user_account_validation_self"],"display":["invalid_credentials"],"password":["not_null_or_undefined"]} */
  errors: object;
}

export interface IApiAuthLogoutBody {
  device?: string;
}

export interface IApiAuthLogoutResponse {
  success: boolean;
}

export interface IAuthResetPassword {
  /** @example "superSecretV4lue" */
  password: string;
  /**
   * Hash received via a 2FA or email
   * @example "hash_value"
   */
  hash: string;
}

export interface IAuthForgotPassword {
  /** @example "test1@example.com" */
  email: string;
}

export interface IApplicativeUserRegisterBody {
  /** @example "my_app" */
  applicativeUserName: string;
}

export interface IApplicativeUserRegisterResponse {
  apiKey: string;
  apiSecret: string;
}

export interface ApiNotAcceptableExceptionDetails {
  minimumExpectedVersion: string;
}

export interface ApiNotAcceptableException {
  statusCode:
    | 100
    | 101
    | 102
    | 103
    | 200
    | 201
    | 202
    | 203
    | 204
    | 205
    | 206
    | 300
    | 301
    | 302
    | 303
    | 304
    | 307
    | 308
    | 400
    | 401
    | 402
    | 403
    | 404
    | 405
    | 406
    | 407
    | 408
    | 409
    | 410
    | 411
    | 412
    | 413
    | 414
    | 415
    | 416
    | 417
    | 418
    | 421
    | 422
    | 424
    | 428
    | 429
    | 500
    | 501
    | 502
    | 503
    | 504
    | 505;
  message: "client_version_deprecated";
  details: ApiNotAcceptableExceptionDetails;
}

export interface IAuthLogoutBody {
  device?: string;
}

export interface IAuthLogoutResponse {
  success: boolean;
}

export interface IAuthSsoLogin {
  /** auth code, required for google sso */
  code?: string;
  /** idToken, required for apple sso */
  idToken?: string;
  /** nonce to verify apple idToken */
  nonce?: string;
  /** firstName, required when registering with apple sso */
  firstName?: string;
  /** lastName, required when registering with apple sso */
  lastName?: string;
  provider: "GOOGLE" | "APPLE";
  /** whether the code was generated using redirect uri in case of google sso */
  hasRedirectUri: boolean;
  /** use localhost as redirect uri */
  isDev?: boolean;
  /**
   * hash sent in case of person being invited to join an organisation
   * @example "aaaa-azerare-fgsdf"
   */
  hash?: string;
  utm?: IUtm;
}

export interface IGoogleCalendarAuthLoginBody {
  /** google oauth code that back will trade for access token */
  code: string;
  /** whether the code was generated using redirect uri */
  hasRedirectUri: boolean;
  /** use localhost as redirect uri */
  isDev?: boolean;
}

export type ICalendarGoogleAuthLoginResponse = object;

export enum UserActivityStatus {
  Active = "active",
  Inactive = "inactive",
  Offline = "offline",
}

export interface IProfile {
  id: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  position?: string;
  profilePicture?: IFile;
  /** Activity status of the user */
  activityStatus: UserActivityStatus;
  isCollaborator: boolean;
}

export interface IProfileResponse {
  data: IProfile;
}

export interface IRoom {
  id: string;
  slug: string;
  /** @example "Acme Corp." */
  name: string;
  /** @example "Acme Corp." */
  description?: string;
  type: RoomTypeEnum;
  createdAt: string;
  updatedAt: string;
  roomPicture?: IFile;
  isHidden: boolean;
}

export interface IOrganisation {
  /** @example "2022-09-28T11:49:48.119Z" */
  createdAt: string;
  /** @example "2022-09-28T11:49:48.119Z" */
  updatedAt: string;
  /** @example 1 */
  id: string;
  /** @example "acme-corp" */
  slug: string;
  /** @example "organisation" */
  type: string;
  /** @example "Acme Corp." */
  name?: string;
  users?: IOrganisationMember[];
  domains?: IDomain[];
  organisationSubscription?: ISubscriptionSummary;
  organisationalRoom?: IRoom;
  canCreateRoom: boolean;
}

export interface IDomain {
  /** @example "2022-09-28T11:49:48.119Z" */
  createdAt: string;
  /** @example "2022-09-28T11:49:48.119Z" */
  updatedAt: string;
  /** @example 1 */
  id: string;
  /** @example "gmail.com" */
  slug: string;
  /**
   * the domain concerned
   * @example "gmail.com"
   */
  name: string;
  organisation?: IOrganisation;
  /**
   * Public is for shared domain providers such as gmail.com
   * @example "private"
   */
  type: string;
}

export interface IUpdateOrganisation {
  /** @example "Acme Corp." */
  name?: string;
  canCreateRoom?: boolean;
}

export interface IOrganisationMembersActiveResponse {
  data: IOrganisationMember[];
}

export interface IOrganisationMembersInvite {
  /** @example "test1@example.com" */
  email: string;
}

export enum OrganisationMemberInviteResponseType {
  Invited = "invited",
  Added = "added",
}

export interface IOrganisationMemberInviteInvitation {
  id: string;
}

export interface IOrganisationMemberInviteOrganisationMember {
  id: string;
}

export interface IOrganisationMembersInviteResponse {
  status: OrganisationMemberInviteResponseType;
  invitation: IOrganisationMemberInviteInvitation;
  organisationMember: IOrganisationMemberInviteOrganisationMember;
}

export interface IOrganisationMembersInviteBatch {
  /** @example ["test1@example.com","test2@example.com"] */
  emails: string[];
}

export interface IOrganisationMembersInviteBatchResponse {
  successes: IOrganisationMembersInviteResponse[];
  /** List of emails that encountered errors. */
  errors: string[];
}

export interface IInvitationCancelManyBody {
  invitationIds: string[];
}

export enum InvitationStatus {
  PendingInvite = "pending_invite",
  Confirmed = "confirmed",
  Canceled = "canceled",
}

export enum InvitationType {
  DefaultJoinOrganisation = "default-join-organisation",
  DefaultJoinRoom = "default-join-room",
}

export interface InvitationOrganisation {
  id: string;
  name: string;
}

export interface InvitationRoom {
  id: string;
  name: string;
}

export interface InvitationInvitedBy {
  firstName: string;
  lastName: string;
}

export interface IInvitation {
  id: string;
  email: string;
  status: InvitationStatus;
  type: InvitationType;
  organisation?: InvitationOrganisation;
  room?: InvitationRoom;
  invitedBy: InvitationInvitedBy;
  createdAt: string;
  updatedAt: string;
}

export interface IOrganisationMembersPendingResponse {
  invitations: IInvitation[];
}

export interface OrganisationMembersSetRoleRole {
  id: string;
}

export interface IOrganisationMembersSetRole {
  role: OrganisationMembersSetRoleRole;
}

export interface IOrganisationMemberArchiveManyBody {
  membershipIds: string[];
}

export interface IOrganisationRoomArchiveManyBody {
  roomIds: string[];
}

export interface IOrganisationRoomsActiveResponse {
  data: IRoom[];
}

export enum RoomMemberStatus {
  Confirmed = "confirmed",
  Archived = "archived",
}

export interface IFolder {
  id: string;
  name?: string;
  createdAt: string;
}

export interface IRoomMember {
  /** @example "2022-09-28T11:49:48.119Z" */
  createdAt: string;
  /** @example "2022-09-28T11:49:48.119Z" */
  updatedAt: string;
  id: string;
  /** @example 1 */
  membershipId: string;
  status: RoomMemberStatus;
  badge?: number;
  /** @example 1 */
  userId: string;
  /** @example "ada-lovelace" */
  userSlug: string;
  userProfilePicture: IFile;
  /** @example "ada-lovelace@gmail.com" */
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  /** @example "CEO" */
  position?: string;
  /** @example 1 */
  roomId: string;
  /** @example "Room Name" */
  name: string;
  room: IRoom;
  role: IRole;
  folder: IFolder;
}

export interface IApiRoomGetOne {
  room: IRoom;
  member: IRoomMember;
}

export interface IFileInput {
  id?: string;
}

export interface IUpdateRoom {
  /** @example "Acme Corp." */
  name?: string;
  /** @example "Acme Corp." */
  description?: string;
  roomPicture?: IFileInput;
  isHidden?: boolean;
}

export interface IQuotaData {
  /** @example "room" */
  quotaType: "member" | "guest" | "room" | "storage";
  /** @example 10 */
  quotaLimit: number;
  /** @example 1 */
  currentNumber: number;
}

export interface IRoomVerifyCreationResponse {
  /** @example "ok" */
  responseCode: "personalAccountRequired" | "organisationAccountRequired" | "emailSentToAdmin" | "ok" | "quotaExceeded";
  quotaData: IQuotaData;
}

export interface IRoomAdd {
  /** @example "Acme Corp." */
  name: string;
  /** @example "Acme Corp." */
  description?: string;
  roomPicture?: IFileInput;
}

export interface IRoomAddResponse {
  /** @example "Acme Corp." */
  room: IRoom;
  /** @example "Acme Corp." */
  member: IRoomMember;
}

export interface IRoomMembersInvite {
  /** @example "test1@example.com" */
  email: string;
}

export enum RoomMembersInviteResponseStatus {
  Added = "added",
  Invited = "invited",
}

export interface IRoomMemberInviteInvitation {
  id: string;
}

export interface IRoomMemberInviteOrganisationMember {
  id: string;
}

export interface IRoomMembersInviteResponse {
  status: RoomMembersInviteResponseStatus;
  invitation: IRoomMemberInviteInvitation;
  roomMember: IRoomMemberInviteOrganisationMember;
}

export interface IRoomMembersInviteBatch {
  /** @example ["test1@example.com","test2@example.com"] */
  emails: string[];
}

export interface IRoomMembersInviteBatchResponse {
  successes: IRoomMembersInviteResponse[];
  /** List of emails that encountered errors. */
  errors: string[];
}

export interface IRoomMembersActiveResponse {
  data: IRoomMember[];
}

export interface RoomMembersSetRoleRole {
  id: string;
}

export interface IRoomMembersSetRole {
  role: RoomMembersSetRoleRole;
}

export interface IRoomMembersPendingResponse {
  invitations: IInvitation[];
}

export interface IPlan {
  /** @example "2022-09-28T11:49:48.119Z" */
  createdAt: string;
  /** @example "2022-09-28T11:49:48.119Z" */
  updatedAt: string;
  /** @example "01H3VZ96DVB4AW0AGMQ5G74CN2" */
  id: string;
  name?: string;
  priceEurosDecimal?: number;
  storageLimitGb?: number;
  memberLimit?: number;
  guestLimit?: number;
  roomLimit?: number;
}

export interface ISubscription {
  /** @example "2022-09-28T11:49:48.119Z" */
  createdAt: string;
  /** @example "2022-09-28T11:49:48.119Z" */
  updatedAt: string;
  /** @example "01H3VZ96DVB4AW0AGMQ5G74CN2" */
  id: string;
  /** @example "cus_O9wFwVpl1izamc" */
  stripeCustomerId?: string;
  organization?: IOrganisation;
  plan?: IPlan;
  email?: string;
  /** @example "active" */
  subscriptionStatus: SubscriptionStatus;
}

export interface IPlanFindManyResponse {
  data: IPlan[];
}

export interface IFolderUpdateBody {
  name: string;
}

export interface INotificationAllBody {
  /** cursor corresponding to the id of the notification */
  cursor?: string;
  /**
   * number of elements to be returned after the cursor
   * @default 0
   */
  nextCount?: number;
  /**
   * number of elements to be returned before the cursor
   * @default 50
   */
  previousCount?: number;
  /**
   * filter by read status
   * @default false
   */
  isRead?: boolean;
}

export enum ConversationMemberStatus {
  Confirmed = "confirmed",
  Removed = "removed",
  Archived = "archived",
}

export interface IConversation {
  id: string;
  type: string;
  members: IConversationMember[];
  messagesCount: number;
}

export interface RoleContainer {
  role: IRole;
}

export interface IMessageReaction {
  count: number;
  senders: IConversationMember[];
}

export interface IUsersJoinRoom {
  subtype: "users-join-room";
  userIds: string[];
  roomId: string;
}

export interface IUsersLeaveRoom {
  subtype: "users-leave-room";
  userIds: string[];
  roomId: string;
}

export interface IUsersJoinCustomChannel {
  subtype: "users-join-custom-channel";
  userIds: string[];
  channelId: string;
}

export interface IUsersLeaveCustomChannel {
  subtype: "users-leave-custom-channel";
  userIds: string[];
  channelId: string;
}

export interface INewVideoconference {
  subtype: "new-videoconference";
  jitsiDomain: string;
  meetingRoomId: string;
}

export interface DeltaOpMention {
  index?: string;
  denotationChar?: string;
  id?: string;
  value?: string;
  email?: string;
}

export interface DeltaOpInsertMention {
  mention: DeltaOpMention;
}

export interface Emoji {
  shortcodes: string;
}

export interface DeltaOpInsertEmoji {
  emoji: Emoji;
}

export interface DeltaOpAttributes {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strike?: boolean;
  link?: string;
  color?: string;
  background?: string;
  script?: string;
  size?: string;
  font?: string;
  code?: boolean;
  header?: number;
  align?: string;
  direction?: string;
  list?: string;
  indent?: number;
  blockquote?: boolean;
  formula?: boolean;
  image?: string;
  video?: string;
}

export interface DeltaOp {
  insert?: string | DeltaOpInsertMention | DeltaOpInsertEmoji;
  retain?: number;
  delete?: number;
  attributes?: DeltaOpAttributes;
}

export interface DeltaContent {
  ops: DeltaOp[];
}

export interface MessageConversationChannel {
  id: string;
  room?: IRoom;
  name?: string;
  description?: string;
}

export interface MessageConversationDirectMessage {
  id: string;
}

export interface MessageConversation {
  id: string;
  type: "channel" | "conversation-group" | "conversation-dm" | "task";
  channel?: MessageConversationChannel;
  directMessage?: MessageConversationDirectMessage;
}

export interface ThreadMessages {
  count: number;
}

export interface ThreadParticipants {
  count: number;
  list: IConversationMember[];
}

export interface Thread {
  messages: ThreadMessages;
  participants: ThreadParticipants;
}

export interface IOriginalMessage {
  id: string;
  createdAt: string;
  conversation?: MessageConversation;
  sender?: IConversationMember;
}

export interface IMessageForward {
  id: string;
  content: DeltaContent;
  originalMessage: IOriginalMessage;
  forwardType?: "default" | "reply";
  files?: IFile[];
}

export interface IMessageMention {
  id: string;
  conversationMember: IConversationMember;
}

export interface ISentFromRoom {
  id: string;
  name: string;
}

export interface IMessage {
  id: string;
  content: DeltaContent;
  contentEditedAt: string | null;
  text?: string;
  type: "default" | "system";
  subtype?: "new-videoconference" | "users-join-room" | "users-leave-room" | "users-join-custom-channel" | "users-leave-custom-channel";
  systemData?: IUsersJoinRoom | IUsersLeaveRoom | IUsersJoinCustomChannel | IUsersLeaveCustomChannel | INewVideoconference;
  createdAt: string;
  deletedAt?: string;
  conversation?: MessageConversation;
  parent?: IMessage;
  sender?: IConversationMember;
  nesting?: "simple" | "parent" | "child";
  reactions?: Record<string, IMessageReaction>;
  thread?: Thread;
  forward?: IMessageForward;
  files?: IFile[];
  mentions?: IMessageMention[];
  sentFromRoom?: ISentFromRoom;
  pinned?: boolean;
}

export interface IConversationMember {
  id: string;
  status: ConversationMemberStatus;
  user: IUser;
  conversation: IConversation;
  role: IRole;
  channelMember?: RoleContainer;
  createdAt: string;
  lastMessageRead: IMessage;
}

export interface ITaskStatus {
  id: string;
  name: string;
  position: string;
  createdAt: string;
  updatedAt: string;
}

export interface IRoomIdName {
  id: string;
  name?: string;
}

export interface IChannelRoomIdName {
  id: string;
  name?: string;
  room?: IRoomIdName;
}

export interface IUserId {
  id: string;
}

export enum TagColorEnum {
  Red = "red",
  Green = "green",
  Blue = "blue",
  Purple = "purple",
  Yellow = "yellow",
  Orange = "orange",
  Grey = "grey",
  Black = "black",
  Pink = "pink",
}

export interface ITag {
  id: string;
  name: string;
  color?: TagColorEnum;
  room: IRoomIdName;
}

export interface ITodoCount {
  checkedCount: number;
  totalCount: number;
}

export enum TaskPriorityEnum {
  High = "high",
  Medium = "medium",
  Low = "low",
}

export interface ITask {
  id: string;
  reference: number;
  title: string;
  description?: DeltaContent;
  taskStatus: ITaskStatus;
  room?: IRoomIdName;
  channel?: IChannelRoomIdName;
  createdByUser: IUserContactInfo;
  assignedUsers?: IUserId[];
  tags?: ITag[];
  todos?: ITodoCount;
  priority?: TaskPriorityEnum;
  conversation: IConversation;
  /** @format date-time */
  deadline?: string;
  position?: number;
  isPrivate: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IReaction {
  id: string;
  content: string;
  createdAt: string;
  message?: IMessage;
  sender?: IConversationMember;
}

export interface INotification {
  id: string;
  type: string;
  createdAt: string;
  isRead: boolean;
  bundleCount: number;
  receiver: IUser;
  sender: IConversationMember;
  sentByUser?: IUser;
  message?: IMessage;
  task?: ITask;
  reaction?: IReaction;
  newNotificationCount?: number;
  emailId?: string;
  emailThreadId?: string;
  room?: IRoom;
}

export interface IPagination {
  nextCursor: string | null;
  previousCursor: string | null;
}

export interface INotificationAllResponse {
  data: INotification[];
  pagination: IPagination;
}

export interface INotificationUpdateBody {
  /**
   * set read status of a notification, only setting to true is supported for now
   * @example false
   */
  isRead?: boolean;
}

export type INotificationUpdateResponse = object;

export type ChannelType = string;

export interface IChannelConversation {
  id: string;
}

export interface IChannel {
  id: string;
  type: ChannelType;
  /** @example "Acme Corp." */
  name?: string;
  /** @example "Acme Corp." */
  description?: string;
  conversation: IChannelConversation;
  room: IRoom;
  createdAt: string;
}

export interface ChannelMemberUser {
  id: string;
  slug: string;
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  position?: string;
  profilePicture?: IFile;
}

export interface ChannelConversation {
  id: string;
  type: string;
}

export interface ChannelMemberChannel {
  id: string;
  name: string;
  description?: string;
  type: string;
  conversation: ChannelConversation;
  createdAt: string;
}

export interface IChannelMember {
  id: string;
  status: string;
  emoji?: string;
  user: ChannelMemberUser;
  channel: ChannelMemberChannel;
  role: IRole;
  createdAt: string;
  updatedAt: string;
  conversationMember: IConversationMember;
  highlightChannel?: boolean;
  badge?: number;
  position: number;
}

export interface IApiChannelGetOne {
  channel: IChannel;
  member: IChannelMember;
}

export interface IChannelAdd {
  /** @example "Acme Corp." */
  name: string;
  /** @example "Acme Corp." */
  description?: string;
}

export interface IChannelAddResponse {
  /** @example "Acme Corp." */
  channel: IChannel;
  /** @example "Acme Corp." */
  member: IChannelMember;
}

export interface IChannelUpdateBody {
  name: string;
}

export interface IChannelMemberInvite {
  /** @example "Acme Corp." */
  roomMemberId: string;
}

export interface IChannelMembersActiveResponse {
  data: IChannelMember[];
}

export interface IChannelSearchBody {
  /**
   * channel query to fuzzy search channel, will search on channel name and channel room name
   * @example "general"
   */
  channelSearchQuery: string;
  /**
   * id of the channel to search from (will be excluded from the results)
   * @example "1"
   */
  channelSearchFromId: string;
}

export interface IChannelSearchResponse {
  data: IChannel[];
}

export interface IConversationMembersActiveResponse {
  data: IConversationMember[];
}

export interface IConversationMembersUpdate {
  /**
   * last message read by the user
   * @example "1"
   */
  lastMessageReadId: string;
}

export type ConversationMessagesSendFiles = object;

export interface IConversationMessagesSend {
  /**
   * object including the array of quill delta ops
   * @example {"ops":["here is the array of quill delta ops"]}
   */
  content: DeltaContent;
  /**
   * parent message Id if this is a nested message
   * @example "ulid"
   */
  parentId?: string;
  /** array of ids of uploaded files */
  files?: ConversationMessagesSendFiles[];
  /** if of the room in which the message is sent, necessary when sending direct messages to open it in room of other members of the dm */
  sentFromRoomId?: string;
  /**
   * Id of the message to forward
   * @example "ulid"
   */
  forwardMessageId?: string;
  /**
   * Type of the forward, either "default" to other conversation or "reply" in one-on-one conversation
   * @default "default"
   * @example "default"
   */
  forwardType?: "default" | "reply";
}

export interface IConversationMessagesSendIntegration {
  /**
   * Content of your message.
   * @example "My awesome message."
   */
  content: string;
}

export interface IConversationMessagesAllSearchBody {
  parentMessageId?: string;
  /** cursor corresponding to the id of a message */
  cursor?: string;
  /**
   * number of elements to be returned after the cursor
   * @default 0
   */
  nextCount?: number;
  /**
   * number of elements to be returned before the cursor
   * @default 50
   */
  previousCount?: number;
  /**
   * include system messages
   * @default true
   */
  includeSystemMessages?: boolean;
}

export interface IConversationMessagesAllSearchResponse {
  data: IMessage[];
  pagination: IPagination;
}

export interface IConversationMessagesUpdate {
  /**
   * object including the array of quill delta ops
   * @example {"ops":["here is the array of quill delta ops"]}
   */
  content: DeltaContent;
}

export interface IConversationMessagesForward {
  /**
   * object including the array of quill delta ops
   * @example {"ops":["here is the array of quill delta ops"]}
   */
  content: DeltaContent;
  /**
   * Id of the conversation to forward the message to
   * @example "ulid"
   */
  forwardToConversationId: string;
  /**
   * Type of the forward, either "default" to other conversation or "reply" in one-on-one conversation
   * @default "default"
   * @example "default"
   */
  forwardType?: "default" | "reply";
}

export interface IApiConversationGetOne {
  conversation: IConversation;
  member: IConversationMember;
}

export interface IConversationReactionsAdd {
  /**
   * short name of the emoji reaction
   * @example ":thumbs up:"
   */
  content: string;
}

export type IConversationReactionsAll = object;

export interface IConversationReactionsAllResponse {
  data: IReaction[];
}

export enum S3FileTypeAccepted {
  Jpg = "jpg",
  Png = "png",
}

export enum FileType {
  Default = "default",
  VoiceNote = "voice-note",
  ProfilePicture = "profile-picture",
  EmailAttachment = "email-attachment",
}

export interface FilesUploadGetPresignedUrlFile {
  name: string;
  mimeType: string;
  extension: S3FileTypeAccepted;
  width?: number;
  height?: number;
  /** audio duration in seconds */
  duration?: number;
  /** @default "default" */
  type?: FileType;
}

export interface IFilesUploadGetPresignedUrlController {
  file: FilesUploadGetPresignedUrlFile;
}

export interface IFilesUploadGetPresignedUrlControllerResponse {
  url: string;
  id: string;
}

export interface UploadedBy {
  id: string;
  firstName: string;
  lastName: string;
}

export interface Conversation {
  conversationId: string;
  /** @example ["channel","conversation-group","conversation-dm","task"] */
  type: "channel" | "conversation-group" | "conversation-dm" | "task";
}

export interface Room {
  id: string;
  name: string;
}

export interface Organisation {
  id: string;
  name: string;
}

export interface Channel {
  id: string;
  name: string;
}

export interface IExtendedFile {
  id: string;
  name: string;
  extension: string;
  url: string;
  createdAt: string;
  image?: IImage;
  audio?: IAudio;
  fileSizeInBytes?: number;
  type: "default" | "voice-note" | "profile-picture" | "email-attachment";
  uploadedBy: UploadedBy;
  conversation: Conversation;
  room?: Room;
  organisation?: Organisation;
  channel?: Channel;
}

export interface IFilesResponse {
  data: IExtendedFile[];
}

export interface IFileSearchBody {
  /** cursor corresponding to the id of a file */
  cursor?: string;
  /**
   * number of elements to be returned after the cursor
   * @default 0
   */
  count?: number;
  roomId?: string;
  organisationId?: string;
  channelId?: string;
  extension?: string;
  date?: string;
  name?: string;
}

export interface IFilesPaginatedResponse {
  data: IExtendedFile[];
  pagination: IPagination;
  fileExtensions: string[];
}

export type IFilesConversionFinishedBody = object;

export type IFilesConversionFinishedResponse = object;

export type IFilesResizingFinishedBody = object;

export type IFilesResizingFinishedResponse = object;

export interface IFilesZipMultipleBody {
  /**
   * files unique identifiers
   * @example ["01GY7Y0NM3XW6VVZYEDS4WD0G9"]
   */
  fileIds: string[];
}

export type IFilesZipMultipleResponse = object;

export interface IFilesZipMultipleFinishedBody {
  /**
   * zip key
   * @example "01GY7Y0NM3XW6VVZYEDS4WD0G9"
   */
  zipKey: string;
  /**
   * user unique identifier
   * @example "01GY7Y0NM3XW6VVZYEDS4WD0G9"
   */
  userId: string;
}

export type IFilesZipMultipleFinishedResponse = object;

export interface IDirectMessageCreate {
  /**
   * list of users to send the message to (should not contain user who is making the request)
   * @example "[1,2,3]"
   */
  recipients: string[];
  /**
   * id of the room where the DM is created from, leave unset to create from home
   * @example "1"
   */
  createFromRoom?: string;
}

export interface IDirectMessageCreateResponse {
  id: string;
  conversation: IConversation;
}

export interface IDirectMessageOpen {
  /**
   * list of users that identifies the direct message, (should not contain user who is making the request)
   * @example "[1,2,3]"
   */
  recipients: string[];
  /**
   * id of the room where the DM is opened from, leave unset to open from home
   * @example "1"
   */
  openFromRoom?: string;
}

export interface IDirectMessage {
  id: string;
  type?: string;
  conversation: IConversation;
  badge?: number;
}

export interface IDirectMessageOpenResponse {
  data: IDirectMessage;
}

export interface IDirectMessageClose {
  /**
   * id of the room where the DM is closed from, leave unset to close from home
   * @example "1"
   */
  closeFromRoom?: string;
}

export interface IDirectMessageCloseResponse {
  data: IDirectMessage;
}

export interface IEventDatetime {
  /** The date, in the format "yyyy-mm-dd", if this is an all-day event. */
  date?: string | null;
  /** The time, as a combined date-time value (formatted according to RFC3339). A time zone offset is required unless a time zone is explicitly specified in timeZone. */
  dateTime?: string | null;
  /** The time zone in which the time is specified. (Formatted as an IANA Time Zone Database name, e.g. "Europe/Zurich".) For recurring events this field is required and specifies the time zone in which the recurrence is expanded. For single events this field is optional and indicates a custom time zone for the event start/end. */
  timeZone?: string | null;
}

export interface IGoogleCalendarEventsCreate {
  start?: IEventDatetime;
  end?: IEventDatetime;
  participantsEmails: string[];
  summary: string;
  description?: string;
}

export type IGoogleCalendarEventsCreateResponse = object;

export interface IGoogleCalendarEventsAllBody {
  /** Lower bound (exclusive) for an event's end time to filter by. Optional. The default is not to filter by end time. Must be an RFC3339 timestamp with mandatory time zone offset, for example, 2011-06-03T10:00:00-07:00, 2011-06-03T10:00:00Z. Milliseconds may be provided but are ignored. If timeMax is set, timeMin must be smaller than timeMax. */
  timeMin?: string;
  /** Upper bound (exclusive) for an event's start time to filter by. Optional. The default is not to filter by start time. Must be an RFC3339 timestamp with mandatory time zone offset, for example, 2011-06-03T10:00:00-07:00, 2011-06-03T10:00:00Z. Milliseconds may be provided but are ignored. If timeMin is set, timeMax must be greater than timeMin. */
  timeMax?: string;
}

export interface ICalendarEvent {
  summary?: string | null;
  description?: string | null;
  start?: IEventDatetime;
  end?: IEventDatetime;
}

export interface IGoogleCalendarEventsAllResponse {
  data: ICalendarEvent[];
}

export interface IMicrosoftCalendarEventsAllBody {
  /** Lower bound (exclusive) for an event's end time to filter by. Must be an RFC3339 timestamp with mandatory time zone offset, for example, 2011-06-03T10:00:00-07:00, 2011-06-03T10:00:00Z. Milliseconds may be provided but are ignored. If timeMax is set, timeMin must be smaller than timeMax. */
  timeMin?: string;
  /** Upper bound (exclusive) for an event's start time to filter by. Must be an RFC3339 timestamp with mandatory time zone offset, for example, 2011-06-03T10:00:00-07:00, 2011-06-03T10:00:00Z. Milliseconds may be provided but are ignored. If timeMin is set, timeMax must be greater than timeMin. */
  timeMax?: string;
}

export interface IMicrosoftCalendarEventsAllResponse {
  data: ICalendarEvent[];
}

export interface IMicrosoftEventDatetime {
  /** The time, as a combined date-time value (formatted according to RFC3339). A time zone offset is required unless a time zone is explicitly specified in timeZone. */
  dateTime: string;
  /** The time zone in which the time is specified. (Formatted as an IANA Time Zone Database name, e.g. "Europe/Zurich".) For recurring events this field is required and specifies the time zone in which the recurrence is expanded. For single events this field is optional and indicates a custom time zone for the event start/end. */
  timeZone: string;
}

export interface IMicrosoftCalendarEventsCreate {
  start?: IMicrosoftEventDatetime;
  end?: IMicrosoftEventDatetime;
  participantsEmails: string[];
  summary: string;
  description?: string;
}

export enum TargetFeatureEnum {
  Calendar = "calendar",
}

export interface IMicrosoftOAuthLoginBody {
  /** microsoft oauth code that back will trade for access token */
  code: string;
  /** whether the code was generated using redirect uri */
  hasRedirectUri: boolean;
  /** use localhost as redirect uri */
  isDev?: boolean;
  targetFeature: TargetFeatureEnum;
}

export interface IMicrosoftOAuthLogoutBody {
  targetFeature: TargetFeatureEnum;
}

export type IMicrosoftOAuthLogoutResponse = object;

export enum GoogleTargetFeatureEnum {
  Calendar = "calendar",
  Gmail = "gmail",
}

export interface IGoogleAuthLoginBody {
  /** google oauth code that back will trade for access token */
  code: string;
  /** whether the code was generated using redirect uri */
  hasRedirectUri: boolean;
  /** use localhost as redirect uri */
  isDev?: boolean;
  targetFeature?: GoogleTargetFeatureEnum;
}

export type IGoogleAuthLoginResponse = object;

export interface IGoogleAuthLogoutBody {
  targetFeature?: GoogleTargetFeatureEnum;
}

export type IGoogleAuthLogoutResponse = object;

export type IMyAccountConvertFromGuestToRegularResponse = object;

export type IMyAccountRequestDeletionResponse = object;

export interface IDirectMessages {
  data: IDirectMessage[];
}

export interface IMyConversation {
  id: string;
  type: string;
  members: IConversationMember[];
  channel?: IChannel;
  directMessage?: IChannel;
}

export interface IMyConversationsResponse {
  data: IMyConversation[];
}

export interface IHomeBadge {
  /** dm badge to show in home page */
  homeDMBadge: boolean;
}

export interface IAuthUpdateMe {
  /** @example "John" */
  firstName?: string;
  /** @example "Doe" */
  lastName?: string;
  /** @example "+330011223344" */
  phone?: string;
  /** @example "CEO" */
  position?: string;
  profilePicture?: IFileInput;
  /** Activity status of the user */
  activityStatus?: UserActivityStatus;
}

export interface IAuthUpdateMyPassword {
  /** @example "John" */
  firstName?: string;
  /** @example "Doe" */
  lastName?: string;
  /** @example "+330011223344" */
  phone?: string;
  /** @example "CEO" */
  position?: string;
  /**
   * The userId-defined new password
   * @example "superSecretV4lue!"
   */
  password: string;
  /**
   * The userId-defined previous password
   * @example "superSecretV4lue!"
   */
  oldPassword: string;
}

export interface IMyRoomsResponse {
  /** list of rooms for given user */
  data: IRoomMember[];
}

export interface MyRoomArchiveTransferOwnershipToMember {
  id: string;
}

export interface IMyRoomArchive {
  /** If you are owner of the room you must provide this property with wanted behaviour - default:false */
  archiveForAllMembers?: boolean;
  /**
   * If you try to remove yourself from room while being owner,
   *   you need to transfer ownership to one of your colleague
   */
  transferOwnershipToMember?: MyRoomArchiveTransferOwnershipToMember;
}

export interface IMyRoomChannels {
  data: IChannelMember[];
}

export interface IMyChannelLeave {
  /**
   * If you try to remove yourself from room while being owner,
   *   you need to transfer ownership to one of your colleague
   */
  archiveForEveryone?: boolean;
}

export interface IMyChannelUpdateBody {
  emoji?: string | null;
}

export interface IMyChannelUpdateResponse {
  data: IChannelMember;
}

export interface IMyChannelReorder {
  /** New position of the channel in the list of channels of the room. First position will be 1 */
  to: number;
}

export interface MyOrganisationLeaveTransferOwnershipToMember {
  id: string;
}

export interface IMyOrganisationLeave {
  /** If you are owner of the organisation you must provide this property with wanted behaviour - default:false */
  closeForAllMembers?: boolean;
  transferOwnershipToMember?: MyOrganisationLeaveTransferOwnershipToMember;
}

export interface ICollaboratorSearchBody {
  /**
   * query to fuzzy search a collaborator by his name
   * @example "robert"
   */
  collaboratorSearchQuery: string;
}

export interface ICollaboratorSearchResponse {
  data: IUser[];
}

export interface IFirebaseTokenSetBody {
  /** string identifying the device of the user */
  device: string;
  /** firebase token for the given device */
  token: string;
}

export type IFirebaseTokenSetResponse = object;

export interface INotificationSettingsSetByConversationBody {
  /**
   * enable notifications for the conversation
   * @default false
   */
  enableNotifications: boolean;
}

export interface IInnerConversationNotificationSettings {
  /** enable notifications for the conversation */
  enableNotifications: boolean;
}

export interface IConversationNotificationSettings {
  /** conversation id */
  id: string;
  /** conversation notification settings */
  notificationSettings: IInnerConversationNotificationSettings;
}

export interface INotificationSettingsGetByRoomResponse {
  /** notification settings for room */
  roomNotificationSettings: IInnerConversationNotificationSettings;
  /** notification settings for conversation */
  conversationNotificationSettings: IConversationNotificationSettings[];
}

export interface INotificationSettingsSetByRoomBody {
  /** enable or disable notifications for all channels in the room */
  enableNotifications: boolean;
}

export interface IInnerRoomNotificationSettings {
  /** enable notifications for the room */
  enableNotifications: boolean;
}

export interface IRoomNotificationSettingsResponse {
  /** room id */
  id: string;
  /** room notification settings */
  notificationSettings: IInnerRoomNotificationSettings;
}

export interface IGeneralNotificationSettings {
  /** general notification settings for notification of type mention */
  mention: boolean;
  /** general notification settings for notification of type direct message */
  directMessage: boolean;
  /** general notification settings for notification of type thread reply */
  threadReply: boolean;
  /** general notification settings for notification of type channel message */
  channelMessage: boolean;
  /** general notification settings for notification of type reaction */
  reaction: boolean;
  /** general notification settings for all type of notifications */
  enableNotifications: boolean;
}

export interface INotificationSettingsGetByGeneralResponse {
  /** notification settings for room */
  generalNotificationSettings: IGeneralNotificationSettings;
  /** notification settings for conversation in the scope general (ie direct messages). */
  conversationNotificationSettings: IConversationNotificationSettings[];
}

export interface INotificationSettingsSetByGeneralBody {
  /**
   * whether to receive notification for any type of notification
   * @example false
   */
  enableNotifications?: boolean;
  /**
   * whether to receive notification when mentioned
   * @example false
   */
  mention?: boolean;
  /**
   * whether to receive notification when receiving direct message
   * @example false
   */
  directMessage?: boolean;
  /**
   * whether to receive notification when receiving thread reply
   * @example false
   */
  threadReply?: boolean;
  /**
   * whether to receive notification when receiving normal channel message
   * @example false
   */
  channelMessage?: boolean;
  /**
   * whether to receive notification when receiving reaction to message
   * @example false
   */
  reaction?: boolean;
}

export interface IMyPinsSearchBody {
  /** cursor corresponding to the id of a pin */
  cursor?: string;
  /**
   * number of elements to be returned after the cursor
   * @default 50
   */
  count?: number;
}

export interface IPin {
  id: string;
  message: IMessage;
  /** @example "2022-09-28T11:49:48.119Z" */
  createdAt: string;
}

export interface IMyPinsResponse {
  data: IPin[];
  pagination: IPagination;
}

export interface IMessageId {
  id: string;
}

export interface IMessagePin {
  id: string;
  message: IMessageId;
  createdAt: string;
}

export interface IMessagePinResponse {
  data: IMessagePin;
}

export enum WebhookEvent {
  MessageSent = "message.sent",
  ReactionAdded = "reaction.added",
}

export interface IWebhook {
  id: string;
  url: string;
  secret: string;
  event: WebhookEvent;
  createdByUser?: IUserId;
  createdAt: string;
  updatedAt: string;
}

export interface IMyWebhooksResponse {
  /** list of webhooks for given user */
  data: IWebhook[];
}

export interface IAddRoomFolderBody {
  /** @example "<folderId>" */
  folderId: string | null;
}

export interface ICreateFolderBody {
  /** @example ["<roomId1>","<roomId2>"] */
  roomIds: string[];
}

export interface IMyOrganisationResponse {
  /** main organisation of a user */
  data: IOrganisation;
}

export interface IVideoconferenceCreate {
  conversationId: string;
}

export interface IVideoconferenceCreateResponse {
  jitsiDomain: string;
  meetingRoomId: string;
}

export interface ITodo {
  id: string;
  value: string;
  /** @example "2022-09-28T11:49:48.119Z" */
  createdAt: string;
  isChecked: boolean;
}

export interface ITodoWithCount {
  todos?: ITodo[];
  checkedCount: number;
  totalCount: number;
}

export interface IConversationMessages {
  id: string;
  type: string;
  members: IConversationMember[];
  messagesCount: number;
  messages?: IMessage[];
}

export interface ITaskOne {
  id: string;
  reference: number;
  title: string;
  description?: DeltaContent;
  taskStatus: ITaskStatus;
  room?: IRoomIdName;
  channel?: IChannelRoomIdName;
  createdByUser: IUserContactInfo;
  assignedUsers?: IUserId[];
  tags?: ITag[];
  todos?: ITodoWithCount;
  priority?: TaskPriorityEnum;
  conversation?: IConversationMessages;
  /** @format date-time */
  deadline?: string;
  position?: number;
  isPrivate: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IGetOneTaskResponse {
  data: ITaskOne;
}

export enum TaskStatusEnum {
  ToDo = "To do",
  Doing = "Doing",
  Done = "Done",
}

export interface ITaskFilter {
  title?: string;
  reference?: number;
  roomId?: string;
  assignedToUserId?: string;
  priority?: TaskPriorityEnum;
  taskStatusId?: string;
  tagIds?: string[];
  priorities?: TaskPriorityEnum[];
}

export enum TaskOrderBy {
  Title = "title",
  TaskStatus = "_taskStatus",
  CreatedAt = "createdAt",
  Position = "position",
  Reference = "reference",
}

export enum SortingOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface OrderBy {
  property: TaskOrderBy;
  order: SortingOrder;
}

export interface ITaskSearchBody {
  /** @deprecated */
  title?: string;
  /** @deprecated */
  roomId?: string;
  /** @deprecated */
  assignedToUserId?: string;
  /** @deprecated */
  priority?: TaskPriorityEnum;
  /** @deprecated */
  status?: TaskStatusEnum;
  where?: ITaskFilter;
  orderBy?: OrderBy[];
}

export interface ITaskSearchResponse {
  data: ITask[];
}

export interface ITaskAddBody {
  title: string;
  description?: DeltaContent;
  channelId: string;
  assignedUsers?: string[];
  tagIds?: string[];
  taskStatusId?: string;
  priority?: TaskPriorityEnum;
  /** @format date-time */
  deadline?: string;
  isPrivate?: boolean;
}

export interface ITaskResponse {
  data: ITask;
}

export interface ITaskUpdateBody {
  title?: string;
  description?: DeltaContent | null;
  taskStatusId?: string;
  channelId?: string;
  priority?: TaskPriorityEnum | null;
  /** @format date-time */
  deadline?: string | null;
  isPrivate?: boolean | null;
  to?: number;
}

export interface ITaskUpdateResponse {
  data: ITask;
}

export interface ITaskStatusCreateBody {
  name: string;
}

export interface ITaskStatusResponse {
  data: ITaskStatus;
}

export interface ITaskStatusUpdateBody {
  name: string;
}

export interface ITaskStatusFindManyResponse {
  data: ITaskStatus[];
}

export interface ITaskStatusReorder {
  /** New index of taskStatus column */
  to: number;
}

export interface ITaskAssignUserBody {
  userId: string;
}

export interface ITagCreateBody {
  name: string;
  color?: TagColorEnum;
  roomId: string;
}

export interface ITagCreateResponse {
  data: ITag;
}

export interface ITagSearchBody {
  roomId: string;
}

export interface ITagSearchResponse {
  data: ITag[];
}

export interface ITagUpdateBody {
  name?: string;
  color?: TagColorEnum;
}

export interface ITodoAddBody {
  value: string;
  optimisticId?: string;
}

export interface ITodoAddResponse {
  data: ITodo;
}

export interface ITodoUpdateBody {
  value?: string;
  isChecked?: boolean;
}

export interface IUrl {
  url: string;
}

export interface ICustomerPortalPageResponse {
  data: IUrl;
}

export interface ISubscriptionConfirmationPageResponse {
  data: IUrl;
}

export interface ISearchMessagesBody {
  /**
   * content of the message to search for
   * @example "message content im looking for"
   */
  messageSearchContent: string;
  /** @default 25 */
  nextCount?: number;
  cursor?: string;
}

export interface ISearchedMessage {
  id: string;
  content: DeltaContent;
  contentEditedAt: string | null;
  text?: string;
  type: "default" | "system";
  subtype?: "new-videoconference" | "users-join-room" | "users-leave-room" | "users-join-custom-channel" | "users-leave-custom-channel";
  systemData?: IUsersJoinRoom | IUsersLeaveRoom | IUsersJoinCustomChannel | IUsersLeaveCustomChannel | INewVideoconference;
  createdAt: string;
  deletedAt?: string;
  conversation?: MessageConversation;
  parent?: IMessage;
  sender?: IConversationMember;
  nesting?: "simple" | "parent" | "child";
  reactions?: Record<string, IMessageReaction>;
  thread?: Thread;
  forward?: IMessageForward;
  files?: IFile[];
  mentions?: IMessageMention[];
  sentFromRoom?: ISentFromRoom;
  pinned?: boolean;
  highlights: string[] | null;
}

export interface ISearchMessagesPagination {
  nextCursor: string | null;
  totalCount: number | null;
}

export interface ISearchMessagesResponse {
  data: ISearchedMessage[];
  pagination: ISearchMessagesPagination;
}

export type IIndexMessagesResponse = object;

export enum WebhookEventEnum {
  MessageSent = "message.sent",
  ReactionAdded = "reaction.added",
}

export interface IWebhookAddBody {
  url: string;
  event: WebhookEventEnum;
  roomId?: string;
  channelId?: string;
}

export interface IWebhookResponse {
  data: IWebhook;
}

export interface IEmailThreadAllBody {
  roomId: string;
  cursor?: string;
  nextCount: number;
}

export interface IEmailIdentity {
  name?: string;
  email: string;
  raw: string;
}

export interface IBodyText {
  visible: string;
  quoted: string;
}

export interface IAttachmentReference {
  id: string;
  name: string;
  mimetype?: string;
  fileSizeInBytes?: number;
}

export interface IEmail {
  id: string;
  from: IEmailIdentity;
  subject: string;
  to: IEmailIdentity[];
  cc?: IEmailIdentity[];
  createdAt: string;
  bodyText: IBodyText;
  bodyHtml?: string;
  attachments: IAttachmentReference[];
}

export interface IEmailThread {
  id: string;
  emailCount?: number;
  lastEmail?: IEmail;
  unreadEmailCount?: number;
}

export interface IEmailThreadAllResponse {
  data: IEmailThread[];
  nextCursor?: object;
}

export interface IEmailThreadOneResponse {
  data: IEmailThread;
}

export interface IEmailThreadEmailsResponse {
  data: IEmail[];
}

export interface IWebhookMessage {
  data: string;
  messageId: string;
  publishTime: string;
}

export interface IWebhookHandlerBody {
  message: IWebhookMessage;
  subscription: string;
}

export type IWebhookHandlerResponse = object;

export interface EmailAttachmentFile {
  id: string;
}

export interface IEmailSend {
  to: string[];
  subject: string;
  text: string;
  html: string;
  replyToMailId?: string;
  /** array of ids of uploaded files */
  files?: EmailAttachmentFile[];
  cc?: string[];
}

export type IEmailSendResponse = object;

export interface IEmailBadge {
  value: number;
}

export interface IEmailBadgeResponse {
  data: IEmailBadge;
}

export interface IEmailOneResponse {
  data: IEmail;
}

export interface IAttachment {
  fileSizeInBytes: number;
  base64UrlEncoded: string;
}

export interface IEmailAttachmentOneResponse {
  data: IAttachment;
}

export interface IEmailThreadMarkAsRead {
  emailThreadId: string;
}

export type IEmailWatchResponse = object;

export type IEmailStopwatchResponse = object;

export interface IImproveTextBody {
  /**
   * object including the array of quill delta ops
   * @example {"ops":["here is the array of quill delta ops"]}
   */
  content: DeltaContent;
  /**
   * text string that guides the model on how to respond
   * @example "I want to improve a given text formatted in quill"
   */
  systemPrompt?: string;
}

export interface IImproveTextResponse {
  data: DeltaContent;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** The default toast error message to show if error is unknown */
  defaultToastErrorMessage?: string | null;
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Roger API Documentation
 * @version 1.0
 * @contact
 *
 * You'll find the socket documentation at /docs-socket
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name RefreshToken
     * @summary refresh your accessToken with a refreshToken
     * @request POST:/api/v1/auth/refresh
     * @secure
     */
    refreshToken: (data: IRefreshToken, params: RequestParams = {}) =>
      this.request<IApiAuthRefreshTokenResponse, IBadRequestError | IForbiddenError>({
        path: `/api/v1/auth/refresh`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Register
     * @summary register a new user
     * @request POST:/api/v1/auth/email/register
     */
    register: (data: IAuthRegisterLogin, params: RequestParams = {}) =>
      this.request<IAuthBaseResponse, IBadRequestError | ApiUnprocessableEntityException>({
        path: `/api/v1/auth/email/register`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name ConfirmEmail
     * @summary allows a user to confirm its account creation with a hash received by email
     * @request POST:/api/v1/auth/email/confirm
     */
    confirmEmail: (data: IAuthConfirmEmail, params: RequestParams = {}) =>
      this.request<IAuthConfirmEmailResponse, IBadRequestError | INotFoundError>({
        path: `/api/v1/auth/email/confirm`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Login
     * @summary login an existing confirmed user with email-password credentials
     * @request POST:/api/v1/auth/email/login
     */
    login: (data: IAuthEmailLogin, params: RequestParams = {}) =>
      this.request<IAuthBaseResponse, IBadRequestError | IUnauthorizedError>({
        path: `/api/v1/auth/email/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name LogOut
     * @summary logout - your refresh token will be canceled
     * @request POST:/api/v1/auth/email/logout
     * @deprecated
     * @secure
     */
    logOut: (data: IApiAuthLogoutBody, params: RequestParams = {}) =>
      this.request<IApiAuthLogoutResponse, IBadRequestError | IForbiddenError>({
        path: `/api/v1/auth/email/logout`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name ResetPassword
     * @summary Second step of password forgotten flow. Will reset the password with a validation hash received by email or 2FA
     * @request POST:/api/v1/auth/reset/password
     */
    resetPassword: (data: IAuthResetPassword, params: RequestParams = {}) =>
      this.request<IUser, IBadRequestError | IForbiddenError>({
        path: `/api/v1/auth/reset/password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description If userId email does not exist, it will return 200 OK instead of 404 Not Found to prevent account discovery
     *
     * @tags Auth
     * @name ForgotPassword
     * @summary First step of password forgotten flow. Will trigger an email sent with a hash used in reset-password
     * @request POST:/api/v1/auth/forgot/password
     */
    forgotPassword: (data: IAuthForgotPassword, params: RequestParams = {}) =>
      this.request<void, IBadRequestError>({
        path: `/api/v1/auth/forgot/password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name RegisterApplicativeUser
     * @summary register a new applicative user
     * @request POST:/api/v1/auth/applicative-user/register
     * @secure
     */
    registerApplicativeUser: (data: IApplicativeUserRegisterBody, params: RequestParams = {}) =>
      this.request<IApplicativeUserRegisterResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/auth/applicative-user/register`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Logout
     * @summary logout from your account
     * @request POST:/api/v1/auth/logout
     * @secure
     */
    logout: (data: IAuthLogoutBody, params: RequestParams = {}) =>
      this.request<IAuthLogoutResponse, IBadRequestError | IForbiddenError>({
        path: `/api/v1/auth/logout`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name SsoLogin
     * @summary login with sso, creates account if user does not already have one
     * @request POST:/api/v1/auth/login
     */
    ssoLogin: (data: IAuthSsoLogin, params: RequestParams = {}) =>
      this.request<IAuthBaseResponse, IBadRequestError | IUnauthorizedError>({
        path: `/api/v1/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  calendars = {
    /**
     * No description
     *
     * @tags Calendars
     * @name GoogleLogin
     * @summary login with google for calendar
     * @request POST:/api/v1/calendars/google/login
     * @secure
     */
    googleLogin: (data: IGoogleCalendarAuthLoginBody, params: RequestParams = {}) =>
      this.request<
        ICalendarGoogleAuthLoginResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/calendars/google/login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendars
     * @name GoogleLogout
     * @summary logout your google account for calendar
     * @request POST:/api/v1/calendars/google/logout
     * @secure
     */
    googleLogout: (params: RequestParams = {}) =>
      this.request<
        ICalendarGoogleAuthLoginResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/calendars/google/logout`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendars
     * @name GoogleCalendarCreateEvent
     * @summary create google calendar event
     * @request POST:/api/v1/calendars/google/events
     * @secure
     */
    googleCalendarCreateEvent: (data: IGoogleCalendarEventsCreate, params: RequestParams = {}) =>
      this.request<IGoogleCalendarEventsCreateResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/calendars/google/events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendars
     * @name SearchCalendarEvents
     * @summary search your calendar events
     * @request POST:/api/v1/calendars/google/events/search
     * @secure
     */
    searchCalendarEvents: (data: IGoogleCalendarEventsAllBody, params: RequestParams = {}) =>
      this.request<
        IGoogleCalendarEventsAllResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/calendars/google/events/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendars
     * @name SearchMicrosoftCalendarEvents
     * @summary search your microsoft calendar events
     * @request POST:/api/v1/calendars/microsoft/events/search
     * @secure
     */
    searchMicrosoftCalendarEvents: (data: IMicrosoftCalendarEventsAllBody, params: RequestParams = {}) =>
      this.request<
        IMicrosoftCalendarEventsAllResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/calendars/microsoft/events/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendars
     * @name MicrosoftCalendarCreateEvent
     * @summary create microsoft calendar event
     * @request POST:/api/v1/calendars/microsoft/events
     * @secure
     */
    microsoftCalendarCreateEvent: (data: IMicrosoftCalendarEventsCreate, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/calendars/microsoft/events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name GetOneProfile
     * @summary get a users profile data
     * @request GET:/api/v1/users/{userId}/profile
     * @secure
     */
    getOneProfile: (userId: string, params: RequestParams = {}) =>
      this.request<IProfileResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/users/${userId}/profile`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  organisations = {
    /**
     * No description
     *
     * @tags Organisations
     * @name GetOne
     * @summary get an organisation by a unique identifier
     * @request GET:/api/v1/organisations/{organisationId}
     * @secure
     */
    getOne: (organisationId: string, params: RequestParams = {}) =>
      this.request<IOrganisation, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/organisations/${organisationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Organisations
     * @name Update
     * @summary partially update an organisation by a unique identifier
     * @request PATCH:/api/v1/organisations/{organisationId}
     * @secure
     */
    update: (organisationId: string, data: IUpdateOrganisation, params: RequestParams = {}) =>
      this.request<IOrganisation, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/organisations/${organisationId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name GetAllActiveMembers
     * @summary returns the list of your main-organisation active members
     * @request GET:/api/v1/organisations/{organisationId}/members/active
     * @secure
     */
    getAllActiveMembers: (organisationId: string, params: RequestParams = {}) =>
      this.request<
        IOrganisationMembersActiveResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/organisations/${organisationId}/members/active`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name InviteMember
     * @summary invite a person in your organisation
     * @request POST:/api/v1/organisations/{organisationId}/members/invite
     * @secure
     */
    inviteMember: (organisationId: string, data: IOrganisationMembersInvite, params: RequestParams = {}) =>
      this.request<
        IOrganisationMembersInviteResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/organisations/${organisationId}/members/invite`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name InviteMembers
     * @summary invite a list of persons in your organisation
     * @request POST:/api/v1/organisations/{organisationId}/members/invite/batch
     * @secure
     */
    inviteMembers: (organisationId: string, data: IOrganisationMembersInviteBatch, params: RequestParams = {}) =>
      this.request<
        IOrganisationMembersInviteBatchResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/organisations/${organisationId}/members/invite/batch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name CancelInvitation
     * @summary cancel an invitation sent to join your organisation
     * @request POST:/api/v1/organisations/{organisationId}/invitations/{invitationId}/cancel
     * @secure
     */
    cancelInvitation: (organisationId: string, invitationId: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/organisations/${organisationId}/invitations/${invitationId}/cancel`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name CancelManyInvitation
     * @summary cancel many invitations sent to join your organisation
     * @request POST:/api/v1/organisations/{organisationId}/invitations/cancel
     * @secure
     */
    cancelManyInvitation: (organisationId: string, data: IInvitationCancelManyBody, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/organisations/${organisationId}/invitations/cancel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name GetAllPendingMembers
     * @summary returns the list of invitations sent to join your organisation
     * @request GET:/api/v1/organisations/{organisationId}/members/pending
     * @secure
     */
    getAllPendingMembers: (organisationId: string, params: RequestParams = {}) =>
      this.request<
        IOrganisationMembersPendingResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/organisations/${organisationId}/members/pending`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name GetRoleOptions
     * @summary returns the list of role options for a given organisation member
     * @request GET:/api/v1/organisations/{organisationId}/members/{membershipId}/set-role/options
     * @secure
     */
    getRoleOptions: (organisationId: string, membershipId: string, params: RequestParams = {}) =>
      this.request<IRole[], IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/organisations/${organisationId}/members/${membershipId}/set-role/options`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name SetRole
     * @summary assign a new role to an existing member of your organisation
     * @request POST:/api/v1/organisations/{organisationId}/members/{membershipId}/set-role
     * @secure
     */
    setRole: (organisationId: string, membershipId: string, data: IOrganisationMembersSetRole, params: RequestParams = {}) =>
      this.request<IOrganisationMember, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/organisations/${organisationId}/members/${membershipId}/set-role`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name RemoveMember
     * @summary remove an existing member from your organisation - will archive any resources this person may own
     * @request POST:/api/v1/organisations/{organisationId}/members/{membershipId}/remove
     * @secure
     */
    removeMember: (organisationId: string, membershipId: string, params: RequestParams = {}) =>
      this.request<IOrganisationMember, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/organisations/${organisationId}/members/${membershipId}/remove`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name ArchiveManyMembers
     * @summary archive existing members from your organisation - will archive any resources this members may own
     * @request POST:/api/v1/organisations/{organisationId}/members/archive
     * @secure
     */
    archiveManyMembers: (organisationId: string, data: IOrganisationMemberArchiveManyBody, params: RequestParams = {}) =>
      this.request<
        IOrganisationMember[],
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/organisations/${organisationId}/members/archive`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name AchiveManyRooms
     * @summary remove existing rooms from your organisation - will archive any resources for the rooms
     * @request POST:/api/v1/organisations/{organisationId}/rooms/archive
     * @secure
     */
    achiveManyRooms: (organisationId: string, data: IOrganisationRoomArchiveManyBody, params: RequestParams = {}) =>
      this.request<IRoom[], IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/organisations/${organisationId}/rooms/archive`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name GetAllActiveRooms
     * @summary returns the list of your main-organisation active room
     * @request GET:/api/v1/organisations/{organisationId}/rooms/active
     * @secure
     */
    getAllActiveRooms: (organisationId: string, params: RequestParams = {}) =>
      this.request<
        IOrganisationRoomsActiveResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/organisations/${organisationId}/rooms/active`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  rooms = {
    /**
     * No description
     *
     * @tags Rooms
     * @name GetOne
     * @summary get a room by a unique identifier
     * @request GET:/api/v1/rooms/{roomId}
     * @secure
     */
    getOne: (roomId: string, params: RequestParams = {}) =>
      this.request<IApiRoomGetOne, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/rooms/${roomId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Rooms
     * @name Update
     * @summary partially update a room by a unique identifier
     * @request PATCH:/api/v1/rooms/{roomId}
     * @secure
     */
    update: (roomId: string, data: IUpdateRoom, params: RequestParams = {}) =>
      this.request<IRoom, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/rooms/${roomId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name VerifyCreation
     * @summary Verify ability to create room : check that user as regular account and he did not reach organization room limit
     * @request POST:/api/v1/rooms/verify/creation
     * @secure
     */
    verifyCreation: (params: RequestParams = {}) =>
      this.request<
        IRoomVerifyCreationResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/rooms/verify/creation`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name Add
     * @summary create a new room
     * @request POST:/api/v1/rooms
     * @secure
     */
    add: (data: IRoomAdd, params: RequestParams = {}) =>
      this.request<IRoomAddResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/rooms`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name InviteMember
     * @summary invite a person in your room
     * @request POST:/api/v1/rooms/{roomId}/members/invite
     * @secure
     */
    inviteMember: (roomId: string, data: IRoomMembersInvite, params: RequestParams = {}) =>
      this.request<
        IRoomMembersInviteResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/rooms/${roomId}/members/invite`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name InviteMembers
     * @summary invite a list of persons in your room
     * @request POST:/api/v1/rooms/{roomId}/members/invite/batch
     * @secure
     */
    inviteMembers: (roomId: string, data: IRoomMembersInviteBatch, params: RequestParams = {}) =>
      this.request<
        IRoomMembersInviteBatchResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/rooms/${roomId}/members/invite/batch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name CancelInvitation
     * @summary cancel an invitation to set your room. If the person already accepted your invitation, you need to leave them from your members list instead
     * @request POST:/api/v1/rooms/{roomId}/invitations/{invitationId}/cancel
     * @secure
     */
    cancelInvitation: (roomId: string, invitationId: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/rooms/${roomId}/invitations/${invitationId}/cancel`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name GetAllActiveMembers
     * @summary returns the list of your room active members
     * @request GET:/api/v1/rooms/{roomId}/members/active
     * @secure
     */
    getAllActiveMembers: (roomId: string, params: RequestParams = {}) =>
      this.request<
        IRoomMembersActiveResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/rooms/${roomId}/members/active`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name ArchiveMember
     * @summary archive an existing member from your room
     * @request POST:/api/v1/rooms/{roomId}/members/{membershipId}/remove
     * @secure
     */
    archiveMember: (roomId: string, membershipId: string, params: RequestParams = {}) =>
      this.request<IRoomMember, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/rooms/${roomId}/members/${membershipId}/remove`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name GetRoleOptions
     * @summary returns the list of role options for a given room member
     * @request GET:/api/v1/rooms/{roomId}/members/{membershipId}/set-role/options
     * @secure
     */
    getRoleOptions: (roomId: string, membershipId: string, params: RequestParams = {}) =>
      this.request<IRole[], IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/rooms/${roomId}/members/${membershipId}/set-role/options`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name SetRole
     * @summary assign a new role to an existing member of your room
     * @request POST:/api/v1/rooms/{roomId}/members/{membershipId}/set-role
     * @secure
     */
    setRole: (roomId: string, membershipId: string, data: IRoomMembersSetRole, params: RequestParams = {}) =>
      this.request<IRoomMember, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/rooms/${roomId}/members/${membershipId}/set-role`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name GetAllPendingMembers
     * @summary returns the list of your room invitations sent
     * @request GET:/api/v1/rooms/{roomId}/members/pending
     * @secure
     */
    getAllPendingMembers: (roomId: string, params: RequestParams = {}) =>
      this.request<
        IRoomMembersPendingResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/rooms/${roomId}/members/pending`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name AddChannel
     * @summary create a new custom channel
     * @request POST:/api/v1/rooms/{roomId}/channels
     * @secure
     */
    addChannel: (roomId: string, data: IChannelAdd, params: RequestParams = {}) =>
      this.request<IChannelAddResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/rooms/${roomId}/channels`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Rooms
     * @name UpdateChannel
     * @summary partially update a channel by a unique identifier
     * @request PATCH:/api/v1/rooms/{roomId}/channels/{channelId}
     * @secure
     */
    updateChannel: (channelId: string, roomId: string, data: IChannelUpdateBody, params: RequestParams = {}) =>
      this.request<IChannel, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/rooms/${roomId}/channels/${channelId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name CreateTaskStatus
     * @summary create a new task Status used as columns for task board
     * @request POST:/api/v1/rooms/{roomId}/task-statuses
     * @secure
     */
    createTaskStatus: (roomId: string, data: ITaskStatusCreateBody, params: RequestParams = {}) =>
      this.request<ITaskStatusResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/rooms/${roomId}/task-statuses`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name FindManyTaskStatuses
     * @summary Get the list of task Statuses for a room, used as columns for task board
     * @request GET:/api/v1/rooms/{roomId}/task-statuses
     * @secure
     */
    findManyTaskStatuses: (roomId: string, params: RequestParams = {}) =>
      this.request<
        ITaskStatusFindManyResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/rooms/${roomId}/task-statuses`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rooms
     * @name DeleteTaskStatus
     * @summary delete an existing task Status used as column for task board
     * @request DELETE:/api/v1/rooms/{roomId}/task-statuses/{taskStatusId}
     * @secure
     */
    deleteTaskStatus: (roomId: string, taskStatusId: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/rooms/${roomId}/task-statuses/${taskStatusId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Rooms
     * @name UpdateTaskStatus
     * @summary update an existing task Status used as column for task board
     * @request PATCH:/api/v1/rooms/{roomId}/task-statuses/{taskStatusId}
     * @secure
     */
    updateTaskStatus: (roomId: string, taskStatusId: string, data: ITaskStatusUpdateBody, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/rooms/${roomId}/task-statuses/${taskStatusId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name ReorderTaskStatus
     * @summary allows yourself to reaorder a taskStatus column
     * @request POST:/api/v1/rooms/{roomId}/task-statuses/{taskStatusId}/reorder
     * @secure
     */
    reorderTaskStatus: (roomId: string, taskStatusId: string, data: ITaskStatusReorder, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/rooms/${roomId}/task-statuses/${taskStatusId}/reorder`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  subscriptions = {
    /**
     * No description
     *
     * @tags Subscriptions
     * @name Validate
     * @summary validate the subscription first time
     * @request POST:/api/v1/subscriptions/{subscriptionId}/validate
     * @secure
     */
    validate: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<ISubscription, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/subscriptions/${subscriptionId}/validate`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name Add
     * @summary add a stripe subscription to a organisation that doesnt have
     * @request POST:/api/v1/subscriptions/organisation/{organisationId}
     * @secure
     */
    add: (organisationId: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/subscriptions/organisation/${organisationId}`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  plans = {
    /**
     * No description
     *
     * @tags Plans
     * @name FindMany
     * @summary Get list of all stripe products
     * @request GET:/api/v1/plans
     * @secure
     */
    findMany: (params: RequestParams = {}) =>
      this.request<
        IPlanFindManyResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/plans`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  folders = {
    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Folders
     * @name UpdateFolder
     * @summary partially update a folder by a unique identifier
     * @request PATCH:/api/v1/folders/{folderId}
     * @secure
     */
    updateFolder: (folderId: string, data: IFolderUpdateBody, params: RequestParams = {}) =>
      this.request<IFolder, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/folders/${folderId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  my = {
    /**
     * No description
     *
     * @tags Me
     * @name GetNotifications
     * @summary get all my notifications
     * @request POST:/api/v1/my/notifications
     * @secure
     */
    getNotifications: (data: INotificationAllBody, params: RequestParams = {}) =>
      this.request<
        INotificationAllResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/my/notifications`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name ConvertAccountFromGuestToRegular
     * @summary Convert guest account to regular account, either personal or organisation account depending on domain public or private.
     * @request POST:/api/v1/my/account/convert-from-guest-to-regular
     * @secure
     */
    convertAccountFromGuestToRegular: (params: RequestParams = {}) =>
      this.request<
        IMyAccountConvertFromGuestToRegularResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/my/account/convert-from-guest-to-regular`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name RequestDeletion
     * @summary Request deletion of my account, will send mail to us and store info in db.
     * @request POST:/api/v1/my/account/request-deletion
     * @secure
     */
    requestDeletion: (params: RequestParams = {}) =>
      this.request<
        IMyAccountRequestDeletionResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/my/account/request-deletion`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name GetDirectMessagesFromHome
     * @summary get the list of your direct messages open in the Home
     * @request GET:/api/v1/my/direct-messages
     * @secure
     */
    getDirectMessagesFromHome: (
      query?: {
        /**
         * Status : confirmed or archived
         * @example "confirmed"
         */
        status?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IDirectMessages, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/direct-messages`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name GetMyConversations
     * @summary get the list of your conversations
     * @request GET:/api/v1/my/conversations
     * @secure
     */
    getMyConversations: (params: RequestParams = {}) =>
      this.request<
        IMyConversationsResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/my/conversations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name GetDirectMessagesHomeBadge
     * @summary get direct message badge to show in home
     * @request GET:/api/v1/my/direct-messages/badge
     * @secure
     */
    getDirectMessagesHomeBadge: (params: RequestParams = {}) =>
      this.request<IHomeBadge, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/direct-messages/badge`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Me
     * @name UpdatePassword
     * @summary change your password. You must be logged in and provide previous password
     * @request PATCH:/api/v1/my/password
     * @secure
     */
    updatePassword: (data: IAuthUpdateMyPassword, params: RequestParams = {}) =>
      this.request<IUser, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/password`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name GetRooms
     * @summary returns the list of your rooms
     * @request GET:/api/v1/my/rooms
     * @secure
     */
    getRooms: (
      query?: {
        /**
         * Status of the room members (confirmed or archived)
         * @example "confirmed"
         */
        status?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IMyRoomsResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/rooms`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description If you are owner of this room you must provide membershipId for an existing member of your organisation to transfer ownership
     *
     * @tags Me
     * @name ArchiveRoom
     * @summary allows yourself to archive a room
     * @request POST:/api/v1/my/rooms/{roomId}/archive
     * @secure
     */
    archiveRoom: (roomId: string, data: IMyRoomArchive, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/rooms/${roomId}/archive`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name GetChannels
     * @summary get the list of your channels in a room
     * @request GET:/api/v1/my/rooms/{roomId}/channels
     * @secure
     */
    getChannels: (
      roomId: string,
      query?: {
        /**
         * Status of the channel members (confirmed or archived)
         * @example "confirmed"
         */
        status?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IMyRoomChannels, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/rooms/${roomId}/channels`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name GetDirectMessagesFromRoom
     * @summary get the list of your open direct messages in a room
     * @request GET:/api/v1/my/rooms/{roomId}/direct-messages
     * @secure
     */
    getDirectMessagesFromRoom: (roomId: string, params: RequestParams = {}) =>
      this.request<IDirectMessages, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/rooms/${roomId}/direct-messages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description If you are owner of this channel you must transfer ownership to another teammate in the channel before leaving
     *
     * @tags Me
     * @name LeaveChannel
     * @summary allows yourself to leave a channel
     * @request POST:/api/v1/my/channels/{channelId}/leave
     * @secure
     */
    leaveChannel: (channelId: string, data: IMyChannelLeave, params: RequestParams = {}) =>
      this.request<IChannelMember, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/channels/${channelId}/leave`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Me
     * @name UpdateChannel
     * @summary update one of my channels (update channel-member entity)
     * @request PATCH:/api/v1/my/channels/{channelId}
     * @secure
     */
    updateChannel: (channelId: string, data: IMyChannelUpdateBody, params: RequestParams = {}) =>
      this.request<
        IMyChannelUpdateResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/my/channels/${channelId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name ReorderChannel
     * @summary allows yourself to reaorder a channel position in the list of rooms channel
     * @request POST:/api/v1/my/channels/{channelId}/reorder
     * @secure
     */
    reorderChannel: (channelId: string, data: IMyChannelReorder, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/channels/${channelId}/reorder`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description If you are owner of this organisation you must provide membershipId for an existing member of your organisation to transfer ownership
     *
     * @tags Me
     * @name LeaveOrganisation
     * @summary allows yourself to leave your organisation
     * @request POST:/api/v1/my/organisations/{organisationId}/leave
     * @secure
     */
    leaveOrganisation: (organisationId: string, data: IMyOrganisationLeave, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/organisations/${organisationId}/leave`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name SearchCollaborators
     * @summary search for your collaborators by name
     * @request POST:/api/v1/my/collaborators
     * @secure
     */
    searchCollaborators: (data: ICollaboratorSearchBody, params: RequestParams = {}) =>
      this.request<
        ICollaboratorSearchResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/my/collaborators`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Me
     * @name SetFirebaseToken
     * @summary set firebase token for your device
     * @request PATCH:/api/v1/my/firebase-token
     * @secure
     */
    setFirebaseToken: (data: IFirebaseTokenSetBody, params: RequestParams = {}) =>
      this.request<
        IFirebaseTokenSetResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/my/firebase-token`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Me
     * @name SetNotificationSettings
     * @summary enable or disable notification settings for a conversation
     * @request PATCH:/api/v1/my/conversations/{conversationId}/notifications-settings
     * @secure
     */
    setNotificationSettings: (conversationId: string, data: INotificationSettingsSetByConversationBody, params: RequestParams = {}) =>
      this.request<
        IConversationNotificationSettings,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/my/conversations/${conversationId}/notifications-settings`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name GetNotificationsSettingsByRoom
     * @summary get notifications settings by room
     * @request GET:/api/v1/my/rooms/{roomId}/notifications-settings
     * @secure
     */
    getNotificationsSettingsByRoom: (roomId: string, params: RequestParams = {}) =>
      this.request<
        INotificationSettingsGetByRoomResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/my/rooms/${roomId}/notifications-settings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Me
     * @name SetNotificationsSettingsByRoom
     * @summary enable or disable notification settings for all channels in a room
     * @request PATCH:/api/v1/my/rooms/{roomId}/notifications-settings
     * @secure
     */
    setNotificationsSettingsByRoom: (roomId: string, data: INotificationSettingsSetByRoomBody, params: RequestParams = {}) =>
      this.request<
        IRoomNotificationSettingsResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/my/rooms/${roomId}/notifications-settings`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name GetNotificationsSettingsByGeneral
     * @summary get general notifications settings for my user
     * @request GET:/api/v1/my/notifications-settings
     * @secure
     */
    getNotificationsSettingsByGeneral: (params: RequestParams = {}) =>
      this.request<
        INotificationSettingsGetByGeneralResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/my/notifications-settings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Me
     * @name SetNotificationSettingsByGeneral
     * @summary set general notification settings for my user
     * @request PATCH:/api/v1/my/notifications-settings
     * @secure
     */
    setNotificationSettingsByGeneral: (data: INotificationSettingsSetByGeneralBody, params: RequestParams = {}) =>
      this.request<
        INotificationSettingsGetByGeneralResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/my/notifications-settings`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name GetPins
     * @summary get the list of your pins
     * @request POST:/api/v1/my/pins
     * @secure
     */
    getPins: (data: IMyPinsSearchBody, params: RequestParams = {}) =>
      this.request<IMyPinsResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/pins`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name PinMessage
     * @summary pin a message adding a pin to it
     * @request POST:/api/v1/my/message/{messageId}/pin
     * @secure
     */
    pinMessage: (messageId: string, params: RequestParams = {}) =>
      this.request<IMessagePinResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/message/${messageId}/pin`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name UnpinMessage
     * @summary unpin a message
     * @request DELETE:/api/v1/my/message/{messageId}/pin
     * @secure
     */
    unpinMessage: (messageId: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/message/${messageId}/pin`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name GetWebhooks
     * @summary get list of webhooks of your organisation
     * @request GET:/api/v1/my/webhooks
     * @secure
     */
    getWebhooks: (params: RequestParams = {}) =>
      this.request<IMyWebhooksResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/webhooks`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Me
     * @name AddRoomToFolder
     * @summary put one room into one folder (or remove from folder)
     * @request PATCH:/api/v1/my/rooms/{roomId}
     * @secure
     */
    addRoomToFolder: (roomId: string, data: IAddRoomFolderBody, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/rooms/${roomId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name CreateFolder
     * @summary orgnise multiple rooms into one folder
     * @request POST:/api/v1/my/rooms/folders
     * @secure
     */
    createFolder: (data: ICreateFolderBody, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/my/rooms/folders`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name GetOrganisation
     * @summary returns your main organisation
     * @request GET:/api/v1/my/organisations
     * @secure
     */
    getOrganisation: (params: RequestParams = {}) =>
      this.request<
        IMyOrganisationResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/my/organisations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  notifications = {
    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Notifications
     * @name UpdateNotificationReadStatus
     * @summary update notification read status
     * @request PATCH:/api/v1/notifications/{notificationId}
     * @secure
     */
    updateNotificationReadStatus: (notificationId: string, data: INotificationUpdateBody, params: RequestParams = {}) =>
      this.request<
        INotificationUpdateResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/notifications/${notificationId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  channels = {
    /**
     * No description
     *
     * @tags Channels
     * @name GetOne
     * @summary get a channel by a unique identifier
     * @request GET:/api/v1/channels/{channelId}
     * @secure
     */
    getOne: (channelId: string, params: RequestParams = {}) =>
      this.request<IApiChannelGetOne, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/channels/${channelId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channels
     * @name InviteMember
     * @summary invite a room member to a custom channel (wont work with default channels)
     * @request POST:/api/v1/channels/{channelId}/members/invite
     * @secure
     */
    inviteMember: (channelId: string, data: IChannelMemberInvite, params: RequestParams = {}) =>
      this.request<IChannelMember, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/channels/${channelId}/members/invite`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channels
     * @name RemoveChannelMember
     * @summary remove channel member (ie. remove the member from the channel)
     * @request POST:/api/v1/channels/members/{channelMemberId}/remove
     * @secure
     */
    removeChannelMember: (channelMemberId: string, params: RequestParams = {}) =>
      this.request<IChannelMember, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/channels/members/${channelMemberId}/remove`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channels
     * @name GetAllActiveMembers
     * @summary returns the list of your channel active members
     * @request GET:/api/v1/channels/{channelId}/members/active
     * @secure
     */
    getAllActiveMembers: (channelId: string, params: RequestParams = {}) =>
      this.request<
        IChannelMembersActiveResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/channels/${channelId}/members/active`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channels
     * @name SearchChannels
     * @summary fuzzy search channel, will search on channel name and channel room name
     * @request POST:/api/v1/channels/search
     * @secure
     */
    searchChannels: (data: IChannelSearchBody, params: RequestParams = {}) =>
      this.request<
        IChannelSearchResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/channels/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  conversations = {
    /**
     * No description
     *
     * @tags Conversations
     * @name GetAllActiveMembers
     * @summary returns the list of your conversation active members
     * @request GET:/api/v1/conversations/{conversationId}/members/active
     * @secure
     */
    getAllActiveMembers: (conversationId: string, params: RequestParams = {}) =>
      this.request<
        IConversationMembersActiveResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/conversations/${conversationId}/members/active`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Conversations
     * @name UpdateConversationMember
     * @summary update a conversation member last message read
     * @request PATCH:/api/v1/conversations/{conversationId}/members/{membershipId}
     * @secure
     */
    updateConversationMember: (conversationId: string, membershipId: string, data: IConversationMembersUpdate, params: RequestParams = {}) =>
      this.request<IConversationMember, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/conversations/${conversationId}/members/${membershipId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Conversations
     * @name MarkALlAsRead
     * @summary mark all messages as read for the conversation member
     * @request POST:/api/v1/conversations/{conversationId}/members/{membershipId}/mark-all-as-read
     * @secure
     */
    markALlAsRead: (conversationId: string, membershipId: string, params: RequestParams = {}) =>
      this.request<IConversationMember, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/conversations/${conversationId}/members/${membershipId}/mark-all-as-read`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Conversations
     * @name SendMessage
     * @summary send a message in a conversation
     * @request POST:/api/v1/conversations/{conversationId}/messages/send
     * @secure
     */
    sendMessage: (conversationId: string, data: IConversationMessagesSend, params: RequestParams = {}) =>
      this.request<IMessage, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/conversations/${conversationId}/messages/send`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Conversations
     * @name GetMessages
     * @summary get conversation messages by a conversation unique identifier
     * @request POST:/api/v1/conversations/{conversationId}/messages
     * @secure
     */
    getMessages: (conversationId: string, data: IConversationMessagesAllSearchBody, params: RequestParams = {}) =>
      this.request<
        IConversationMessagesAllSearchResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/conversations/${conversationId}/messages`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Conversations
     * @name ForwardMessage
     * @summary Forward a message to another conversation
     * @request POST:/api/v1/conversations/{conversationId}/messages/{messageId}/forward
     * @secure
     */
    forwardMessage: (conversationId: string, messageId: string, data: IConversationMessagesForward, params: RequestParams = {}) =>
      this.request<IMessage, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/conversations/${conversationId}/messages/${messageId}/forward`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Conversations
     * @name GetOne
     * @summary get a conversation by a unique identifier
     * @request GET:/api/v1/conversations/{conversationId}
     * @secure
     */
    getOne: (conversationId: string, params: RequestParams = {}) =>
      this.request<
        IApiConversationGetOne,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/conversations/${conversationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Conversations
     * @name GetReactions
     * @summary get recent reactions in a conversation
     * @request POST:/api/v1/conversations/{conversationId}/reactions/all
     * @secure
     */
    getReactions: (conversationId: string, data: IConversationReactionsAll, params: RequestParams = {}) =>
      this.request<
        IConversationReactionsAllResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/conversations/${conversationId}/reactions/all`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  public = {
    /**
     * No description
     *
     * @tags Conversations
     * @name SendMessageIntegration
     * @summary send a message in a conversation
     * @request POST:/api/v1/public/conversations/{conversationId}/messages/send
     * @secure
     */
    sendMessageIntegration: (conversationId: string, data: IConversationMessagesSendIntegration, params: RequestParams = {}) =>
      this.request<IMessage, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/public/conversations/${conversationId}/messages/send`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  messages = {
    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Conversations
     * @name UpdateMessage
     * @summary update a message in a conversation
     * @request PATCH:/api/v1/messages/{messageId}
     * @secure
     */
    updateMessage: (messageId: string, data: IConversationMessagesUpdate, params: RequestParams = {}) =>
      this.request<IMessage, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/messages/${messageId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Conversations
     * @name RemoveMessage
     * @summary remove a message from a conversation
     * @request DELETE:/api/v1/messages/{messageId}
     * @secure
     */
    removeMessage: (messageId: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/messages/${messageId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Conversations
     * @name AddReaction
     * @summary add a reaction to a message in a conversation
     * @request POST:/api/v1/messages/{messageId}/reactions/add
     * @secure
     */
    addReaction: (messageId: string, data: IConversationReactionsAdd, params: RequestParams = {}) =>
      this.request<IReaction, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/messages/${messageId}/reactions/add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Conversations
     * @name RemoveReaction
     * @summary remove a reaction to a message in a conversation
     * @request DELETE:/api/v1/messages/{messageId}/reactions/{reactionName}/remove
     * @secure
     */
    removeReaction: (messageId: string, reactionName: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/messages/${messageId}/reactions/${reactionName}/remove`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @tags Files
     * @name GetPresignedUrlToUpload
     * @summary get a s3 presigned url to upload a file
     * @request POST:/api/v1/files/files/upload/presigned-url
     * @secure
     */
    getPresignedUrlToUpload: (data: IFilesUploadGetPresignedUrlController, params: RequestParams = {}) =>
      this.request<
        IFilesUploadGetPresignedUrlControllerResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/files/files/upload/presigned-url`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name GetRoomFiles
     * @summary get rooms files by the room unique identifier
     * @request GET:/api/v1/files/rooms/{roomId}
     * @secure
     */
    getRoomFiles: (roomId: string, params: RequestParams = {}) =>
      this.request<IFilesResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/files/rooms/${roomId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name GetFilteredPaginatedFiles
     * @summary get filetered and paginated files
     * @request POST:/api/v1/files/search
     * @secure
     */
    getFilteredPaginatedFiles: (data: IFileSearchBody, params: RequestParams = {}) =>
      this.request<
        IFilesPaginatedResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/files/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FileConversionFinished
     * @summary notify api that a file conversion has finished
     * @request POST:/api/v1/files/{fileId}/conversion-finished
     * @secure
     */
    fileConversionFinished: (fileId: string, data: IFilesConversionFinishedBody, params: RequestParams = {}) =>
      this.request<
        IFilesConversionFinishedResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/files/${fileId}/conversion-finished`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FileResizingFinished
     * @summary notify api that a file resizing has finished
     * @request POST:/api/v1/files/{fileId}/resizing-finished
     * @secure
     */
    fileResizingFinished: (fileId: string, data: IFilesResizingFinishedBody, params: RequestParams = {}) =>
      this.request<
        IFilesResizingFinishedResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/files/${fileId}/resizing-finished`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FileZipMultiple
     * @summary initiate creation of archive of multiple files asynchronously
     * @request POST:/api/v1/files/zip-multiple
     * @secure
     */
    fileZipMultiple: (data: IFilesZipMultipleBody, params: RequestParams = {}) =>
      this.request<
        IFilesZipMultipleResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/files/zip-multiple`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FileZipMultipleFinished
     * @summary notify that archive of multiple files is ready to download
     * @request POST:/api/v1/files/zip-multiple-finished
     * @secure
     */
    fileZipMultipleFinished: (data: IFilesZipMultipleFinishedBody, params: RequestParams = {}) =>
      this.request<
        IFilesZipMultipleFinishedResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/files/zip-multiple-finished`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  directMessages = {
    /**
     * No description
     *
     * @tags DirectMessages
     * @name CreateDirectMessage
     * @summary create a direct message between multiple users
     * @request POST:/api/v1/directMessages/create
     * @secure
     */
    createDirectMessage: (data: IDirectMessageCreate, params: RequestParams = {}) =>
      this.request<IDirectMessageCreateResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/directMessages/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DirectMessages
     * @name OpenDirectMessage
     * @summary open a direct message identified by its list of users
     * @request POST:/api/v1/directMessages/open
     * @secure
     */
    openDirectMessage: (data: IDirectMessageOpen, params: RequestParams = {}) =>
      this.request<
        IDirectMessageOpenResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/directMessages/open`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DirectMessages
     * @name CloseDirectMessage
     * @summary close a direct message identified by its id
     * @request POST:/api/v1/directMessages/{directMessageId}/close
     * @secure
     */
    closeDirectMessage: (directMessageId: string, data: IDirectMessageClose, params: RequestParams = {}) =>
      this.request<
        IDirectMessageCloseResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/directMessages/${directMessageId}/close`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  microsoft = {
    /**
     * No description
     *
     * @tags Microsoft
     * @name MicrosoftCalendarOAuthLogin
     * @summary login with microsoft for calendar
     * @request POST:/api/v1/microsoft/login
     * @secure
     */
    microsoftCalendarOAuthLogin: (data: IMicrosoftOAuthLoginBody, params: RequestParams = {}) =>
      this.request<
        IMicrosoftOAuthLoginBody,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/microsoft/login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Microsoft
     * @name MicrosoftCalendarLogout
     * @summary logout your microsoft account for calendar
     * @request POST:/api/v1/microsoft/logout
     * @secure
     */
    microsoftCalendarLogout: (data: IMicrosoftOAuthLogoutBody, params: RequestParams = {}) =>
      this.request<
        IMicrosoftOAuthLogoutResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/microsoft/logout`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  google = {
    /**
     * No description
     *
     * @tags Google
     * @name GoogleLogin
     * @summary login with google to access calendar, gmail, etc
     * @request POST:/api/v1/google/login
     * @secure
     */
    googleLogin: (data: IGoogleAuthLoginBody, params: RequestParams = {}) =>
      this.request<
        IGoogleAuthLoginResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/google/login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Google
     * @name GoogleLogout
     * @summary logout your google account from using calendar, gmail, etc
     * @request POST:/api/v1/google/logout
     * @secure
     */
    googleLogout: (data: IGoogleAuthLogoutBody, params: RequestParams = {}) =>
      this.request<
        IGoogleAuthLogoutResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/google/logout`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  health = {
    /**
     * No description
     *
     * @tags Health
     * @name HealthControllerCheck
     * @request GET:/api/v1/health
     */
    healthControllerCheck: (params: RequestParams = {}) =>
      this.request<
        {
          /** @example "ok" */
          status?: string;
          /** @example {"database":{"status":"up"}} */
          info?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {} */
          error?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {"database":{"status":"up"}} */
          details?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
        },
        {
          /** @example "error" */
          status?: string;
          /** @example {"database":{"status":"up"}} */
          info?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {"redis":{"status":"down","message":"Could not connect"}} */
          error?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {"database":{"status":"up"},"redis":{"status":"down","message":"Could not connect"}} */
          details?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
        }
      >({
        path: `/api/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  me = {
    /**
     * No description
     *
     * @tags Me
     * @name GetMe
     * @summary retrieve your user data with your bearer accessToken
     * @request GET:/api/v1/me
     * @secure
     */
    getMe: (params: RequestParams = {}) =>
      this.request<IUser, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Me
     * @name Update
     * @summary change your user data with your bearer accessToken
     * @request PATCH:/api/v1/me
     * @secure
     */
    update: (data: IAuthUpdateMe, params: RequestParams = {}) =>
      this.request<IUser, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/me`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  invitations = {
    /**
     * No description
     *
     * @tags Invitations
     * @name GetByHash
     * @summary returns invitation data based on given hash
     * @request GET:/api/v1/invitations/{hash}
     */
    getByHash: (hash: string, params: RequestParams = {}) =>
      this.request<IInvitation, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/invitations/${hash}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  videoconference = {
    /**
     * No description
     *
     * @tags Videoconference
     * @name CreateVideoconference
     * @summary Create a videoconference
     * @request POST:/api/v1/videoconference
     * @secure
     */
    createVideoconference: (data: IVideoconferenceCreate, params: RequestParams = {}) =>
      this.request<IVideoconferenceCreateResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/videoconference`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  tasks = {
    /**
     * No description
     *
     * @tags Tasks
     * @name GetOne
     * @summary get one task details
     * @request GET:/api/v1/tasks/{taskId}
     * @secure
     */
    getOne: (taskId: string, params: RequestParams = {}) =>
      this.request<IGetOneTaskResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tasks/${taskId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Tasks
     * @name Update
     * @summary update an existing task
     * @request PATCH:/api/v1/tasks/{taskId}
     * @secure
     */
    update: (taskId: string, data: ITaskUpdateBody, params: RequestParams = {}) =>
      this.request<ITaskUpdateResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tasks/${taskId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name DeleteOne
     * @summary Hard delete one task given its id
     * @request DELETE:/api/v1/tasks/{taskId}
     * @secure
     */
    deleteOne: (taskId: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tasks/${taskId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name SearchTasks
     * @summary Search list of tasks filtered
     * @request POST:/api/v1/tasks/search
     * @secure
     */
    searchTasks: (data: ITaskSearchBody, params: RequestParams = {}) =>
      this.request<ITaskSearchResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tasks/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name Add
     * @summary create a new task
     * @request POST:/api/v1/tasks
     * @secure
     */
    add: (data: ITaskAddBody, params: RequestParams = {}) =>
      this.request<ITaskResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/tasks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name DeleteTaskAssignedUser
     * @summary dete assigned user from a task
     * @request DELETE:/api/v1/tasks/{taskId}/assigned-users/{assignedUserId}
     * @secure
     */
    deleteTaskAssignedUser: (taskId: string, assignedUserId: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tasks/${taskId}/assigned-users/${assignedUserId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name AssignUser
     * @summary assign task to a user
     * @request POST:/api/v1/tasks/{taskId}/assigned-users
     * @secure
     */
    assignUser: (taskId: string, data: ITaskAssignUserBody, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tasks/${taskId}/assigned-users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name AddTag
     * @summary add tag to a task
     * @request POST:/api/v1/tasks/{taskId}/tags/{tagId}
     * @secure
     */
    addTag: (taskId: string, tagId: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tasks/${taskId}/tags/${tagId}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name DeleteTaskTag
     * @summary delete tag from a task
     * @request DELETE:/api/v1/tasks/{taskId}/tags/{tagId}
     * @secure
     */
    deleteTaskTag: (taskId: string, tagId: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tasks/${taskId}/tags/${tagId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name AddTodo
     * @summary add todo to a task
     * @request POST:/api/v1/tasks/{taskId}/todos
     * @secure
     */
    addTodo: (taskId: string, data: ITodoAddBody, params: RequestParams = {}) =>
      this.request<ITodoAddResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tasks/${taskId}/todos`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name DeleteTodo
     * @summary delete todo from a task
     * @request DELETE:/api/v1/tasks/{taskId}/todos/{todoId}
     * @secure
     */
    deleteTodo: (taskId: string, todoId: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tasks/${taskId}/todos/${todoId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Tasks
     * @name UpdateTodo
     * @summary update an existing todo
     * @request PATCH:/api/v1/tasks/{taskId}/todos/{todoId}
     * @secure
     */
    updateTodo: (taskId: string, todoId: string, data: ITodoUpdateBody, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tasks/${taskId}/todos/${todoId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  tags = {
    /**
     * No description
     *
     * @tags Tags
     * @name CreateTag
     * @summary create a new tag
     * @request POST:/api/v1/tags
     * @secure
     */
    createTag: (data: ITagCreateBody, params: RequestParams = {}) =>
      this.request<ITagCreateResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/tags`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tags
     * @name SearchTags
     * @summary Search list of tags of a room
     * @request POST:/api/v1/tags/search
     * @secure
     */
    searchTags: (data: ITagSearchBody, params: RequestParams = {}) =>
      this.request<ITagSearchResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tags/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tags
     * @name DeleteOne
     * @summary Hard delete one tag given its id
     * @request DELETE:/api/v1/tags/{tagId}
     * @secure
     */
    deleteOne: (tagId: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tags/${tagId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description You don't need to provide all properties. Only ones provided will be updated
     *
     * @tags Tags
     * @name Update
     * @summary update an existing tag
     * @request PATCH:/api/v1/tags/{tagId}
     * @secure
     */
    update: (tagId: string, data: ITagUpdateBody, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/tags/${tagId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  pages = {
    /**
     * No description
     *
     * @tags Pages
     * @name GetPortalUrl
     * @summary Get url to access customer portal
     * @request GET:/api/v1/pages/customerportal
     * @secure
     */
    getPortalUrl: (params: RequestParams = {}) =>
      this.request<
        ICustomerPortalPageResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/pages/customerportal`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name GetSubscriptionConfirmationPage
     * @summary Get access to the customer portal
     * @request GET:/api/v1/pages/subscription/plan/{planId}
     * @secure
     */
    getSubscriptionConfirmationPage: (planId: string, params: RequestParams = {}) =>
      this.request<
        ISubscriptionConfirmationPageResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/pages/subscription/plan/${planId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  webhooks = {
    /**
     * No description
     *
     * @tags Webhooks
     * @name WebhookControllerWebhookController
     * @request POST:/api/v1/webhooks/stripe
     */
    webhookControllerWebhookController: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/webhooks/stripe`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Webhooks
     * @name Add
     * @summary register a new webhook
     * @request POST:/api/v1/webhooks
     * @secure
     */
    add: (data: IWebhookAddBody, params: RequestParams = {}) =>
      this.request<IWebhookResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/webhooks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Webhooks
     * @name DeleteOne
     * @summary Hard delete one Webhook given its id
     * @request DELETE:/api/v1/webhooks/{webhookId}
     * @secure
     */
    deleteOne: (webhookId: string, params: RequestParams = {}) =>
      this.request<void, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/webhooks/${webhookId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  search = {
    /**
     * No description
     *
     * @tags Search
     * @name SearchMessages
     * @summary search messages by content
     * @request POST:/api/v1/search/messages
     * @secure
     */
    searchMessages: (data: ISearchMessagesBody, params: RequestParams = {}) =>
      this.request<
        ISearchMessagesResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/search/messages`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Search
     * @name IndexMessages
     * @summary Index all messages
     * @request POST:/api/v1/search/index-messages
     * @secure
     */
    indexMessages: (params: RequestParams = {}) =>
      this.request<
        IIndexMessagesResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/search/index-messages`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  emails = {
    /**
     * No description
     *
     * @tags Emails
     * @name GetMyThreads
     * @summary get your email threads in a room
     * @request POST:/api/v1/emails/threads
     * @secure
     */
    getMyThreads: (data: IEmailThreadAllBody, params: RequestParams = {}) =>
      this.request<
        IEmailThreadAllResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/emails/threads`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Emails
     * @name GetOneThread
     * @summary get one email thread
     * @request GET:/api/v1/emails/threads/{threadId}
     * @secure
     */
    getOneThread: (threadId: string, params: RequestParams = {}) =>
      this.request<
        IEmailThreadOneResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/emails/threads/${threadId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Emails
     * @name GetOneThreadEmails
     * @summary get all emails in a thread
     * @request GET:/api/v1/emails/threads/{threadId}/emails
     * @secure
     */
    getOneThreadEmails: (threadId: string, params: RequestParams = {}) =>
      this.request<
        IEmailThreadEmailsResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/emails/threads/${threadId}/emails`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Emails
     * @name HandleGmailWebhook
     * @summary handle gmail webhook
     * @request POST:/api/v1/emails/webhook
     */
    handleGmailWebhook: (data: IWebhookHandlerBody, params: RequestParams = {}) =>
      this.request<
        IWebhookHandlerResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/emails/webhook`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Emails
     * @name SendEmail
     * @summary send an email
     * @request POST:/api/v1/emails
     * @secure
     */
    sendEmail: (data: IEmailSend, params: RequestParams = {}) =>
      this.request<IEmailSendResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/emails`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Emails
     * @name GetEmailBadge
     * @summary get badge counting number of unread email threads in a room
     * @request GET:/api/v1/emails/badge
     * @secure
     */
    getEmailBadge: (
      query: {
        roomId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IEmailBadgeResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/emails/badge`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Emails
     * @name GetOneEmail
     * @summary get one email
     * @request GET:/api/v1/emails/{emailId}
     * @secure
     */
    getOneEmail: (emailId: string, params: RequestParams = {}) =>
      this.request<IEmailOneResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/emails/${emailId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Emails
     * @name GetOneEmailAttachment
     * @summary get one email attachment
     * @request GET:/api/v1/emails/{emailId}/attachments/{attachmentId}
     * @secure
     */
    getOneEmailAttachment: (emailId: string, attachmentId: string, params: RequestParams = {}) =>
      this.request<
        IEmailAttachmentOneResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/emails/${emailId}/attachments/${attachmentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Emails
     * @name EmailThreadMarkAsRead
     * @summary mark email thread as read
     * @request POST:/api/v1/emails/threads/mark-as-read
     * @secure
     */
    emailThreadMarkAsRead: (data: IEmailThreadMarkAsRead, params: RequestParams = {}) =>
      this.request<IEmailSendResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/emails/threads/mark-as-read`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Emails
     * @name WatchEmail
     * @summary watch email
     * @request POST:/api/v1/emails/watch
     * @secure
     */
    watchEmail: (params: RequestParams = {}) =>
      this.request<IEmailWatchResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void>({
        path: `/api/v1/emails/watch`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Emails
     * @name StopwatchEmail
     * @summary stopwatch email
     * @request POST:/api/v1/emails/stopwatch
     * @secure
     */
    stopwatchEmail: (params: RequestParams = {}) =>
      this.request<
        IEmailStopwatchResponse,
        IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException | void
      >({
        path: `/api/v1/emails/stopwatch`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  improveText = {
    /**
     * No description
     *
     * @tags Ai
     * @name ImproveText
     * @summary improve a given text using AI GPT models
     * @request POST:/api/v1/improve-text
     * @secure
     */
    improveText: (data: IImproveTextBody, params: RequestParams = {}) =>
      this.request<IImproveTextResponse, IBadRequestError | IUnauthorizedError | IForbiddenError | ApiNotAcceptableException | void>({
        path: `/api/v1/improve-text`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
