import { ConversationComponent } from "@/components/roomPage/tabs/conversation/ConversationComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { RoundedContainerComponent } from "@/components/shared/layout/RoundedContainerComponent";
import { CommentHeaderComponent } from "@/components/shared/tasks/EditTaskDrawerComponent/CommentHeaderComponent";
import { useEditTaskDrawer } from "@/components/shared/tasks/EditTaskDrawerComponent/useEditTaskDrawer";
import { ConversationContext } from "@/contexts/ConversationContext";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BsExclamationCircle as InformationIcon } from "react-icons/bs";
import { RiCloseFill as CloseIcon } from "react-icons/ri";

interface IEditTaskDrawerComponentProps {
  taskId: string;
}

export const EditTaskDrawerComponent: FunctionComponent<IEditTaskDrawerComponentProps> = ({ taskId }) => {
  const { t } = useTranslation();
  const {
    task,
    isPending,
    isFresh,
    isSaving,
    onCloseDrawer,
    conversationId,
    conversationContextValue,
    isError,
    isNotFoundError,
    updateTask,
    onTaskDelete,
  } = useEditTaskDrawer(taskId);

  return (
    <RoundedContainerComponent className="bg-dark-25 dark:bg-dark-800" hasPadding={false}>
      <section className="relative h-full">
        {isError ? (
          <div className="flex h-full flex-col items-center justify-center py-16">
            <InformationIcon className="mb-4 h-8 w-8 text-zinc-800" />
            <div className="mb-2 font-semibold text-zinc-800">{t("editTaskModal.error.title")}</div>
            <div className="text-xs font-semibold text-zinc-600">
              {isNotFoundError ? t("editTaskModal.error.message.notFound") : t("editTaskModal.error.message.default")}
            </div>
          </div>
        ) : isPending || task == null || conversationContextValue == null || conversationId == null ? (
          <LoaderComponent />
        ) : (
          <ConversationContext.Provider value={conversationContextValue}>
            <div className="flex h-full flex-col pt-16" id={`conversation-${conversationId}`}>
              <ConversationComponent
                contextId={conversationId}
                contextType="taskComment"
                key={`conversation-taskComment${conversationId}`}
                Header={
                  <CommentHeaderComponent
                    task={task}
                    conversationId={conversationId}
                    conversationContextValue={conversationContextValue}
                    isPending={isPending}
                    updateTask={updateTask}
                    deleteTask={onTaskDelete}
                  />
                }
                isComment
              />
            </div>
          </ConversationContext.Provider>
        )}
        <div className="absolute inset-x-0 top-0 flex h-14 w-full flex-row items-center justify-between border-b bg-dark-25 px-6">
          <h1 className="font-semibold text-zinc-800">
            {t("editTaskModal.title")}
            <span className="text-xs font-normal text-zinc-400">
              {isFresh ? "" : isSaving ? t(`editTaskModal.saving`) : t(`editTaskModal.saved`)}
            </span>
          </h1>
          <CloseIcon className="h-5 w-5 cursor-pointer" onClick={onCloseDrawer} />
        </div>
      </section>
    </RoundedContainerComponent>
  );
};
